export function newSerialQueue<T>(): SerialQueue<T> {
  return new SerialQueue<T>();
}

type Task<T> = {
  fn: () => Promise<T>;
  resolve: (value: T | PromiseLike<T>) => void;
  reject: (reason?: any) => void;
};

export class SerialQueue<T> {
  private q: Task<T>[];
  private waker: (() => void) | null;

  constructor() {
    this.q = [];
    this.waker = null;
    this.queueWorker();
  }

  private async queueWorker(): Promise<never> {
    for (;;) {
      const t = this.q.shift();
      if (!t) {
        await this.sleepMode();
        continue;
      }
      try {
        const result = await t.fn();
        t.resolve(result);
      } catch (e) {
        t.reject(e);
      }
    }
  }

  private sleepMode(): Promise<void> {
    return new Promise((resolve) => {
      this.waker = resolve;
    });
  }

  async push(fn: () => Promise<T>): Promise<T> {
    return new Promise<T>((resolve, reject) => {
      this.q.push({
        fn,
        resolve,
        reject,
      });
      if (this.waker) {
        this.waker();
        this.waker = null;
      }
    });
  }
}
