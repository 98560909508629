import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { isFeatureFlagEnabled, useQueryParamFFSetting } from "./featureFlag";
import { parse } from "./parser";
import { google } from "./protocol.pb";
import { loginOptions, useProgramSync } from "./useProgramSync";
import { ScreenSize, useScreenSize } from "./useScreenSize";
import { nextTick } from "./util";

enum Currency {
  UNK = "UNK", // unknown currency, used as a negative value
  USD = "USD",
  EUR = "EUR",
  GBP = "GBP",
  BRL = "BRL",
}

const CurrencySymbols: Readonly<Record<Currency, string>> = {
  UNK: "?",
  BRL: "R$",
  USD: "$",
  EUR: "€",
  GBP: "£",
};

const detectCurrency = (s: string): [Currency, boolean] => {
  s = s.trim();
  const commentStartIdx = s.indexOf("//");
  if (commentStartIdx >= 0) {
    s = s.slice(0, commentStartIdx);
  }
  for (const [currency, sym] of Object.entries(CurrencySymbols)) {
    if ((currency as Currency) === Currency.UNK) {
      continue;
    }
    if (s.includes(sym)) {
      return [currency as Currency, true];
    }
  }
  return [Currency.UNK, false];
};

const BROWSER_LOCALE =
  navigator.languages.find((l) => l.includes("-")) || "en-US";

const formatNumber = (n: number, currency: Currency = Currency.UNK): string => {
  const options: Intl.NumberFormatOptions = {};
  const useExponentialNotation = n > 10e8;
  if (useExponentialNotation) {
    options.notation = "scientific";
  }
  if (currency !== Currency.UNK) {
    options.style = "currency";
    options.currency = currency as string;
  }
  const formatter = new Intl.NumberFormat(BROWSER_LOCALE, options);
  return formatter.format(n);
};

interface TotalDisplayProps {
  total: number;
  currency: Currency;
  lineSelection: { start: number; end: number };
}

const TotalDisplay: React.FC<TotalDisplayProps> = ({
  total,
  currency,
  lineSelection,
}) => {
  let linesVisbility: React.CSSProperties["visibility"] = "hidden";
  if (lineSelection.start !== lineSelection.end) {
    linesVisbility = "visible";
  }
  return (
    <div className="mt-2 font-mono text-sm text-right text-primary">
      <div>
        total:&nbsp;
        <span>{formatNumber(total, currency)}</span>
      </div>
      <div>
        {lineSelection.start !== lineSelection.end && (
          <span style={{ fontSize: "0.8em" }}>
            &nbsp;(Lines {lineSelection.start + 1}-{lineSelection.end + 1})
          </span>
        )}
      </div>
    </div>
  );
};

const LoadingDots: React.FC<
  React.HTMLAttributes<HTMLDivElement> & {
    prefix?: string;
  }
> = ({ prefix, ...props }) => {
  const [numDots, setNumDots] = useState(0);
  useEffect(() => {
    var itvl = setInterval(() => {
      setNumDots((cv) => {
        return cv + 1;
      });
    }, 125);
    return () => {
      clearInterval(itvl);
    };
  }, []);
  return (
    <div {...props}>
      {prefix && <span>{prefix}</span>}
      {".".repeat((numDots % 3) + 1)}
    </div>
  );
};

const UserStuff: React.FC<{}> = () => {
  const { isAuthenticated, isLoading, user, logout, loginWithRedirect } =
    useAuth0();

  if (!isFeatureFlagEnabled("accounts")) {
    return <></>;
  }

  return (
    <>
      <div className="mt-5 text-xs p-5 bg-info text-yogurt rounded-md shadow-inner">
        {isLoading && <LoadingDots prefix="loading" />}
        {!isLoading && (
          <div>
            {isAuthenticated && (
              <div className="flex items-center">
                {user?.picture && (
                  <div className="mr-3">
                    <img
                      alt="user icon"
                      className="rounded-full shadow-md w-8 h-8"
                      src={user?.picture}
                    />
                  </div>
                )}
                <div>
                  <span className="font-bold">logged in:</span> {user?.email}
                  &nbsp;|&nbsp;(
                  <a
                    className="hover:underline italic"
                    href="#logout"
                    onClick={() => {
                      logout({
                        returnTo: `${global.location.origin}/#logout-ok`,
                      });
                    }}
                  >
                    logout
                  </a>
                  )
                </div>
              </div>
            )}
            {!isAuthenticated && (
              <div className="text-sedona hover:underline">
                <a
                  href="#login"
                  onClick={() => {
                    loginWithRedirect(loginOptions());
                  }}
                >
                  login
                </a>
              </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

type TimestampProps = React.HTMLAttributes<HTMLSpanElement> & {
  value: google.protobuf.Timestamp | null | undefined;
};

function fmttime(d: Date): string {
  const f = new Intl.DateTimeFormat(navigator.language, {
    timeStyle: "short",
  });
  return f.format(d);
}

function fmtdatetime(d: Date): string {
  const f = new Intl.DateTimeFormat(navigator.language, {
    dateStyle: "medium",
    timeStyle: "short",
  });
  return f.format(d);
}

const Timestamp: React.FC<TimestampProps> = ({ value, ...props }) => {
  if (!value) {
    return <span {...props}></span>;
  }
  const d = new Date(value.seconds * 1000);
  const dayNo = Math.floor(Date.now() / 86400000);
  const inDayNo = Math.floor(value.seconds / 86400);
  if (dayNo === inDayNo) {
    return <span {...props}>{fmttime(d)}</span>;
  }
  return <span {...props}>{fmtdatetime(d)}</span>;
};

type LineMessage = {
  type: "result" | "parse-error" | "space";
  contents: string;
};

type TextareaAttrs = React.DetailedHTMLProps<
  React.TextareaHTMLAttributes<HTMLTextAreaElement>,
  HTMLTextAreaElement
>;

type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {};

const Button: React.FC<ButtonProps> = ({ children, ...props }) => {
  const className = clsx(
    props.className,
    "px-3",
    "py-1",
    "bg-primary",
    "text-light",
    "text-slate-100",
    "rounded-full",
    "shadow-sm"
  );
  return (
    <button {...props} className={className}>
      {children}
    </button>
  );
};

interface EditorProps extends TotalDisplayProps {
  onSelect: TextareaAttrs["onSelect"];
  cols: TextareaAttrs["cols"];
  value: TextareaAttrs["value"];
  onChange: TextareaAttrs["onChange"];
  lineMessages: LineMessage[];
  disabled: boolean;
}

function unreachable(x: never): void {}

const Editor: React.FC<EditorProps> = ({
  onSelect,
  value,
  cols,
  onChange,
  lineMessages,
  disabled,
  ...props
}) => {
  const [screenSize] = useScreenSize();
  const taRef = useRef<HTMLTextAreaElement>(null);
  const gutterRef = useRef<HTMLDivElement>(null);
  const [resultGutterHeight, setResultGutterHeight] =
    useState<React.CSSProperties["height"]>();
  useEffect(() => {
    setResultGutterHeight(taRef.current?.clientHeight);
  }, [taRef.current?.clientHeight]);

  useEffect(() => {
    if (taRef.current && gutterRef.current) {
      const ignoreNext = new Map<HTMLElement, boolean>();
      const makeListener = (a: HTMLElement, b: HTMLElement) => {
        return () => {
          if (ignoreNext.get(a)) {
            ignoreNext.delete(a);
            return;
          }
          ignoreNext.set(b, true);
          requestAnimationFrame(() => {
            b.scrollTo({ top: a.scrollTop });
          });
        };
      };
      const taListener = makeListener(taRef.current, gutterRef.current);
      const gutterListener = makeListener(gutterRef.current, taRef.current);

      taRef.current.addEventListener("scroll", taListener);
      gutterRef.current.addEventListener("scroll", gutterListener);

      return () => {
        taRef.current?.removeEventListener("scroll", taListener);
        gutterRef.current?.removeEventListener("scroll", gutterListener);
      };
    }
    return () => {};
  }, [taRef.current, gutterRef.current]);

  let gutterStyle: React.CSSProperties = {};
  if (screenSize > ScreenSize.SM) {
    gutterStyle = { ...gutterStyle, width: "150px", right: "-160px" };
  } else {
    gutterStyle = { ...gutterStyle, right: "0px" };
  }

  return (
    <div className="flex relative">
      <div className="z-10">
        <textarea
          className="p-2 font-mono text-sm rounded-tl-md rounded-bl-md sm:rounded-md resize-none shadow-sm opacity-100 focus-visible:outline-2 focus-visible:outline-offset-[-2px] focus-visible:outline-primary"
          ref={taRef}
          disabled={disabled}
          cols={cols}
          rows={15}
          onSelect={onSelect}
          value={value}
          onChange={onChange}
        />
      </div>
      <div
        className="sm:absolute flex-grow sm:flex-grow-[none] z-0"
        style={gutterStyle}
      >
        <div
          ref={gutterRef}
          className={clsx({
            flex: true,
            "flex-col": true,
            "no-scrollbar": true,
            "sm:text-left": true,
            "h-80": true,
            "text-right": true,
            "overflow-y-scroll": true,
            "text-ellipsis": true,
            "max-w-max": true,
            "sm:max-w-none": true,
            "overflow-x-hidden": true,
            "px-4": true,
            "py-2": true,
            "font-mono": true,
            "text-sm": true,
            "bg-secondary": true,
            "text-light": true,
            "rounded-tr-md": true,
            "rounded-br-md": true,
            "sm:rounded-md": true,
            "shadow-sm": true,
          })}
          style={{
            height: resultGutterHeight,
          }}
        >
          {lineMessages.map((lm, i): React.ReactNode => {
            switch (lm.type) {
              case "result":
                return <div key={i}>{lm.contents}</div>;
              case "parse-error":
                return (
                  <div key={i} title={lm.contents} className="text-warning">
                    !!
                  </div>
                );
              case "space":
                return <div key={i}>&nbsp;</div>;
            }
            unreachable(lm.type);
          })}
        </div>
        <TotalDisplay
          total={props.total}
          currency={props.currency}
          lineSelection={props.lineSelection}
        />
      </div>
    </div>
  );
};

function App() {
  useQueryParamFFSetting();

  const [
    { program, busy, documents, documentName, documentOpen },
    { program: setProgram, name: setDocumentName },
    docActions,
    { openDocument, deleteDocument },
  ] = useProgramSync();

  const [lineSelection, setLineSelection] = useState({ start: 0, end: 0 });
  const [lineMessages, setLineMessages] = useState<LineMessage[]>([]);
  const [total, setTotal] = useState<number>(0);
  const [currency, setCurrency] = useState(Currency.UNK);
  const [screenSize] = useScreenSize();
  const taCols = screenSize <= ScreenSize.SM ? 30 : 50;

  const populateTutorial = useCallback(() => {
    if (program.length) {
      // eslint-disable-next-line no-restricted-globals
      let cont = confirm(
        [
          "Looks like you have some stuff",
          " already here, are you sure you want to",
          " continue and replace all of that?",
        ].join("")
      );
      if (!cont) {
        return;
      }
    }
    setProgram(
      [
        `1 + 2 // addition, results on the right =>`,
        `9 - 8 // subtraction`,
        `2 * 2 // multiplication`,
        `2 / 2 // division`,
        `(2 + 2) / 4 // use parenthesis`,
        `-$5 + 3 // use dollar signs if you want`,
        `@other section`,
        `// ⬆️ lines that begin with "@" are skipped`,
        `4 + 38 // comments can be added with "//"`,
      ].join("\n")
    );
  }, [program]);
  useEffect(() => {
    const LS_KEY = "solvy.first_visit";
    const firstVisit = localStorage.getItem(LS_KEY);
    if (!firstVisit) {
      localStorage.setItem(LS_KEY, new Date().toISOString());
      if (!program.length) {
        populateTutorial();
      }
    }
  }, [program, populateTutorial]);

  useEffect(() => {
    let balance = 0;
    let lm: LineMessage[] = [];
    let currency = Currency.UNK;
    const lines = program.split("\n");
    let lineno = -1;
    const limitTotalByLine = lineSelection.start !== lineSelection.end;
    for (let line of lines) {
      lineno++;
      try {
        line = line.trim();
        for (
          let i = 0;
          i < Math.floor(Math.max(0, line.length - 1) / taCols);
          i++
        ) {
          lm.push({
            type: "space",
            contents: "",
          });
        }
        const [lineResult] = parse(line);
        if (lineResult === null) {
          lm.push({
            type: "space",
            contents: "",
          });
          continue;
        }
        const [curr, hasCurrency] = detectCurrency(line);
        if (hasCurrency && currency === Currency.UNK) {
          currency = curr;
        }
        if (lineResult !== null) {
          if (!limitTotalByLine) {
            balance += lineResult;
          } else if (
            lineno >= lineSelection.start &&
            lineno <= lineSelection.end
          ) {
            balance += lineResult;
          }
          lm.push({
            type: "result",
            contents: formatNumber(lineResult, currency),
          });
        }
      } catch (e) {
        lm.push({
          type: "parse-error",
          contents: `failed to parse line: ${(e as any).message}`,
        });
      }
    }

    setLineMessages(lm);
    // prevent -0 display: if the result of rounding to the 5th decimal place
    // turns into -0, then just set zero
    if (Object.is(round5(balance), -0)) {
      setTotal(0);
    } else {
      setTotal(balance);
    }
    setCurrency(currency);
  }, [program, taCols, lineSelection]);

  const wrapperClass = clsx({
    flex: true,
    // "justify-start": screenSize <= ScreenSize.MD,
    "justify-center": true, // screenSize > ScreenSize.MD,
  });

  return (
    <div className={wrapperClass}>
      <div className="sm:w-auto w-full">
        <div className="mb-5">
          <h2 className="text-3xl mb-2 text-primary">solvy</h2>
          <p className="text-secondary">
            A little app for simple calculations.
            <sup
              className="underline decoration-dotted cursor-pointer"
              title="click to load example document!"
              onClick={populateTutorial}
            >
              ?
            </sup>
          </p>
        </div>

        <Editor
          value={program}
          disabled={busy}
          lineMessages={lineMessages}
          cols={taCols}
          onChange={(e) => {
            setProgram(e.target.value);
          }}
          onSelect={async (e) => {
            // super weird bug: when holding down a key to repeatedly enter a
            // key or backspace, the cursor would jump to the end of the program
            // and begin adding/removing characters from there. by waiting for
            // the next tick instead of processing syncrhonously, we can avoid
            // this somehow?! no idea how that works, but it does.
            await nextTick();

            const LF_ASCII = 0xa;
            const { selectionStart, selectionEnd } =
              e.target as HTMLTextAreaElement;
            let line = 0;
            let lineStart: number | null = null;
            let lineEnd: number | null = null;
            // atypical "<=" comparison due to an off-by-one bug when
            // selectionEnd is at the very end of the string
            for (let i = 0; i <= program.length; i++) {
              if (i === selectionStart) {
                lineStart = line;
              }
              if (i === Math.max(0, selectionStart, selectionEnd - 1)) {
                lineEnd = line;
                break;
              }
              if (program.charCodeAt(i) === LF_ASCII) {
                line++;
              }
            }
            // console.log("selection_debug", {
            //   lineStart,
            //   lineEnd,
            //   selectionStart,
            //   selectionEnd,
            //   prog_len: program.length,
            // });
            if (lineStart !== null && lineEnd !== null) {
              setLineSelection({ start: lineStart, end: lineEnd });
            }
          }}
          total={total}
          currency={currency}
          lineSelection={lineSelection}
        />
        <div>
          <div>
            {documentOpen && (
              <div>
                <div className="flex items-center mt-3">
                  <div>
                    <label>
                      <span>name&nbsp;</span>
                      <input
                        className="p-2 rounded-md shadow-sm focus-visible:outline-offset-[-2px]"
                        value={documentName}
                        onChange={(e) => {
                          setDocumentName(e.target.value);
                        }}
                      />
                    </label>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-3">
              {docActions.map((b, i) => (
                <Button
                  className="mr-3"
                  key={`file-action-${i}`}
                  disabled={b.disabled}
                  title={b.title}
                  onClick={() => {
                    b.do();
                  }}
                >
                  {b.name}
                </Button>
              ))}
            </div>
          </div>
          {documents && (
            <div className="mt-4 p-4 shadow-inner rounded-md bg-info">
              {documents.length === 0 && (
                <div className="text-center">
                  <span className="text-xs">(no documents)</span>
                </div>
              )}
              {documents.length > 0 &&
                documents.map((d, i) => (
                  <div
                    className="flex justify-between cursor-pointer hover:text-underline"
                    key={`doc-${i}`}
                  >
                    <div
                      onClick={() => {
                        openDocument(d.id);
                      }}
                    >
                      <b>{d.name || "Untitled"}</b>
                    </div>
                    <div className="right">
                      <Timestamp
                        className="text-xs"
                        onClick={() => {
                          openDocument(d.id);
                        }}
                        value={d.created_date}
                      />
                      &nbsp;&nbsp;
                      <span
                        title="delete document"
                        className="delete"
                        onClick={() => {
                          deleteDocument(d.id);
                        }}
                      >
                        &#128465;
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
        <UserStuff />
        <div>
          <br />
          <small className="text-xs text-secondary">
            powered by cloudflare{" "}
            <a
              className="hover:underline italic"
              href="https://pages.cloudflare.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              pages
            </a>
            &nbsp;and&nbsp;
            <a
              className="hover:underline italic"
              href="https://workers.cloudflare.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              workers
            </a>
          </small>
        </div>
      </div>
    </div>
  );
}

export default App;

function round5(n: number): number {
  return Math.round(n * 100000) / 100000;
}
