import { useAuth0 } from "@auth0/auth0-react";
import { GetTokenSilentlyOptions } from "@auth0/auth0-spa-js";
import { useCallback } from "react";

export function nextTick(): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(resolve, 0);
  });
}

export function debugLog(message?: any, ...optionalParams: any[]): void {
  if (process.env.NODE_ENV === "development") {
    if (typeof message === "string") {
      message = `debug: ${message}`;
    } else {
      optionalParams = [message, ...optionalParams];
      message = "debug";
    }
    console.log(message, ...optionalParams);
  }
}

const ACCESS_TOKEN_UNAVAILABLE = "a3498023-b476-4149-90cc-e03d1ae8aa12";

export function useAuthTokenIfAvailable(): (
  options?: GetTokenSilentlyOptions | undefined
) => Promise<string> {
  const { getAccessTokenSilently } = useAuth0();
  return useCallback(
    async (options?: GetTokenSilentlyOptions | undefined): Promise<string> => {
      try {
        return await getAccessTokenSilently(options);
      } catch {
        return ACCESS_TOKEN_UNAVAILABLE;
      }
    },
    [getAccessTokenSilently]
  );
}

export function useAuthedFetcher(): typeof fetch {
  const getAccessTokenSilently = useAuthTokenIfAvailable();
  return useCallback<typeof fetch>(
    async (input, init) => {
      const accessToken = await getAccessTokenSilently();
      if (accessToken !== ACCESS_TOKEN_UNAVAILABLE) {
        if (init) {
          if (!init.headers) {
            init.headers = { Authorization: `Bearer ${accessToken}` };
          } else {
            let headers = new Headers(init.headers);
            headers.set("Authorization", `Bearer ${accessToken}`);
            init.headers = headers;
          }
        }
      }
      return fetch(input, init);
    },
    [getAccessTokenSilently]
  );
}
