import { RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import Automerge from "automerge";
import { Change, diffChars } from "diff";
import React, { MutableRefObject, useEffect, useRef, useState } from "react";
import { SolvyDocument } from "../types";
import { isFeatureFlagEnabled } from "./featureFlag";
import { google, solvy } from "./protocol.pb";
import { sendSolvyFLProtobufRequest } from "./protocol.twirp";
import { newSerialQueue, SerialQueue } from "./serialQueue";
import { debugLog, useAuthedFetcher } from "./util";

const chunkString = (str: string, size: number) => {
  var i,
    j,
    chunk = size,
    out: string[] = [];
  for (i = 0, j = str.length; i < j; i += chunk) {
    out.push(str.slice(i, i + chunk));
  }
  return out;
};

function sleep(ms: number): Promise<void> {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

function loadBinaryDocument<T>(input: Uint8Array): Automerge.FreezeObject<T> {
  const bd = input as Automerge.BinaryDocument;
  bd.__binaryDocument = true;
  return Automerge.load<T>(bd);
}

export const hexStringToUint8Array = (hexString: string) =>
  new Uint8Array(chunkString(hexString, 2).map((byte) => parseInt(byte, 16)));

export const Uint8ArrayToHexString = (bytes: Uint8Array) =>
  bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, "0"), "");

function useDebounceQueue<T>(
  ms: number,
  processQueue: (items: T[]) => Promise<void>
): [(...items: T[]) => void, boolean] {
  const timerRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const buffer = useRef<T[]>([]);
  const [isProcessing, setIsProcessing] = useState(false);
  const doProcessQueue = () => {
    if (buffer.current.length > 0) {
      debugLog("useDebounceQueue: flushing...", {
        bufferSize: buffer.current.length,
      });
      setIsProcessing(true);
      processQueue(buffer.current)
        .then(() => {
          debugLog("clearing buffer...");
          buffer.current = [];
        })
        .finally(() => {
          setIsProcessing(false);
        });
    }
  };
  return [
    (...items: T[]): void => {
      if (timerRef.current) {
        clearTimeout(timerRef.current);
        timerRef.current = null;
      }
      buffer.current.push(...items);
      if (!isProcessing) {
        timerRef.current = setTimeout(() => {
          doProcessQueue();
        }, ms);
      }
    },
    buffer.current.length > 0,
  ];
}

const diffCharsAsync = async (
  oldStr: string,
  newStr: string
): Promise<Change[]> => {
  return new Promise<Change[]>((resolve, reject) => {
    diffChars(oldStr, newStr, (err, changes) => {
      if (err) {
        reject(err);
        return;
      }
      if (!changes) {
        resolve([{ count: oldStr.length, value: oldStr }]);
      } else {
        resolve(changes);
      }
    });
  });
};

interface SolvyClient {
  call: typeof sendSolvyFLProtobufRequest;
}

function useSolvyClient(): SolvyClient {
  const authFetch = useAuthedFetcher();
  const r = useRef<SolvyClient>({
    call: (method, request, options) => {
      options.fetcher = authFetch;
      options.prefix = "rpc";
      return sendSolvyFLProtobufRequest(method as any, request, options) as any;
    },
  });
  return r.current;
}

export function loginOptions(): RedirectLoginOptions {
  return {
    redirectUri: `${global.location.origin}/#login-ok`,
  };
}
type DocumentAction = {
  name: "Open" | "Save" | "Delete" | "Close" | "Cancel";
  disabled: boolean;
  title?: string;
  do: () => Promise<void>;
};

type ProgramSyncState = {
  documentOpen: boolean;
  documentName: string;
  program: string;
  busy: boolean;
  documents?: solvy.worker.DocumentInfo[];
};

async function retry<T = any>(fn: () => Promise<T>): Promise<T> {
  for (let a = 1; ; a++) {
    await sleep(Math.min(Math.pow(2, a) * 50, 5000));
    try {
      return fn();
    } catch (e) {
      debugLog("retry: error occurred", { error: e });
    }
  }
}

function usePing() {
  const client = useSolvyClient();
  const { isLoading: isAuthLoading } = useAuth0();
  useEffect(() => {
    if (!isAuthLoading) {
      const request = solvy.worker.PingRequest.create({});
      client.call("Ping", request, {}).catch(() => {});
    }
  }, [isAuthLoading, client]);
}

type BidirectionalState<S> = {
  previous: S;
  current: S;
  rx: boolean;
};

type BidirectionalSetState<S> = {
  rx: React.Dispatch<S>;
  tx: React.Dispatch<S>;
};

function useBidirectionalState<S>(
  initialState: () => S
): [BidirectionalState<S>, BidirectionalSetState<S>] {
  const [v, setv] = useState<BidirectionalState<S>>(() => {
    const iv = initialState();
    return {
      previous: iv,
      current: iv,
      rx: false,
    };
  });

  return [
    v,
    {
      rx: (value: S): void => {
        setv((current) => {
          return {
            previous: current.current,
            current: value,
            rx: true,
          };
        });
      },
      tx: (value: S): void => {
        setv((current) => {
          return {
            previous: current.current,
            current: value,
            rx: false,
          };
        });
      },
    },
  ];
}

function analyzeDiff(
  field: "program" | "name",
  value: BidirectionalState<string>,
  sq: SerialQueue<any>,
  amDoc: MutableRefObject<Automerge.FreezeObject<SolvyDocument>>,
  queueChangeSend: (...items: Automerge.BinaryChange[]) => void
) {
  if (value.previous === value.current || value.rx) {
    debugLog("ignoring changes", { ...value, field });
    return;
  }
  // kick off the diffing process _synchronously_ so that the value
  // of `program` doesn't change underneath us
  const diffPromise = diffCharsAsync(value.previous, value.current);

  // push the diff to changes process into a serial queue that will ensure
  // each version of that will update the AM doc and generate sync messages
  // serially
  sq.push(async (): Promise<any> => {
    const changes = await diffPromise;
    debugLog("processing changes...", { changes });
    const newAmDoc = Automerge.change<SolvyDocument>(amDoc.current, (doc_1) => {
      let offset: number = 0;
      let texts: Record<typeof field, Automerge.Text> = {
        program: doc_1.program,
        name: doc_1.name,
      };
      const text = texts[field];
      for (let change of changes) {
        if (change.added) {
          text.insertAt && text.insertAt(offset, ...change.value.split(""));
          offset += change.count || 0;
        } else if (change.removed) {
          text.deleteAt && text.deleteAt(offset, change.value.length);
        } else {
          offset += change.count || 0;
        }
      }
    });
    const bchanges: Automerge.BinaryChange[] = Automerge.getChanges(
      amDoc.current,
      newAmDoc
    );
    if (changes.length > 0) {
      queueChangeSend(...bchanges);
    }
    amDoc.current = newAmDoc;
  });
}

function toHexString(bytes: Uint8Array): string {
  return bytes.reduce(
    (str: string, byte: number) => str + byte.toString(16).padStart(2, "0"),
    ""
  );
}

function fromHexString(hexString: string): Automerge.BinaryDocument {
  const ua = new Uint8Array(
    chunkString(hexString, 2).map((byte) => parseInt(byte, 16))
  );
  (ua as Automerge.BinaryDocument).__binaryDocument = true;
  return ua as Automerge.BinaryDocument;
}

interface ProgramSyncActions {
  openDocument(id: string): Promise<void>;
  deleteDocument(id: string): Promise<void>;
}

export function useProgramSync(): [
  ProgramSyncState,
  {
    program: React.Dispatch<string>;
    name: React.Dispatch<string>;
  },
  DocumentAction[],
  ProgramSyncActions
] {
  usePing();
  const rpc = useSolvyClient();
  const { isAuthenticated } = useAuth0();

  const [documents, setDocuments] = useState<
    solvy.worker.DocumentInfo[] | undefined
  >();
  const [documentID, setDocumentID] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);
  const [docName, setDocName] = useBidirectionalState<string>(() => "");
  const [program, setProgram] = useBidirectionalState<string>(() => "");
  const sq = useRef(newSerialQueue<any>());
  const amDoc = useRef<Automerge.FreezeObject<SolvyDocument>>(
    Automerge.from<SolvyDocument>({
      name: new Automerge.Text(""),
      program: new Automerge.Text(""),
    })
  );

  // TODO: write change buffer to LS for durability
  const [queueChangeSend, unclearedBuffer] =
    useDebounceQueue<Automerge.BinaryChange>(1000, async (changes) => {
      if (!documentID) {
        debugLog("no document current open, skipping PushDocumentChange");
        return;
      }
      return retry(async () => {
        await rpc.call(
          "PushDocumentChange",
          solvy.worker.PushDocumentChangeRequest.create({
            id: documentID,
            binary_change: changes,
          }),
          {}
        );
      });
    });

  useEffect(() => {
    analyzeDiff("program", program, sq.current, amDoc, queueChangeSend);
  }, [program]);
  useEffect(() => {
    analyzeDiff("name", docName, sq.current, amDoc, queueChangeSend);
  }, [docName]);

  const setAmDoc = (inAmDoc: Automerge.FreezeObject<SolvyDocument>): void => {
    amDoc.current = inAmDoc;
    setProgram.rx(amDoc.current.program.toString());
    setDocName.rx(amDoc.current.name.toString());
  };

  useEffect(() => {
    const savedDocData = localStorage.getItem("solvy.document");
    if (!isFeatureFlagEnabled("accounts") && savedDocData) {
      debugLog("localstorage_program_restore");
      const bd = fromHexString(savedDocData);
      const savedDoc = Automerge.load<SolvyDocument>(bd);
      setAmDoc(Automerge.merge(amDoc.current, savedDoc));
    }
    setLoading(false);
  }, []);
  useEffect(() => {
    debugLog("localstorage_program_save");
    const bd = Automerge.save(amDoc.current);
    localStorage.setItem("solvy.document", toHexString(bd));
  }, [amDoc.current]);

  const docActions: DocumentAction[] = [];

  useEffect(() => {
    (globalThis as any).getAmDoc = () => amDoc.current;
    (globalThis as any).Automerge = Automerge;
    (globalThis as any).google = google;
  }, []);

  if (isAuthenticated) {
    const docListMode = !!documents;
    const documentIsOpen = !!documentID;
    if (docListMode) {
      docActions.push({
        name: "Cancel",
        disabled: false,
        async do() {
          setDocuments(undefined);
        },
      });
    }
    if (!docListMode && !documentIsOpen) {
      docActions.push({
        name: "Open",
        disabled: loading,
        async do() {
          setLoading(true);
          try {
            const response = await rpc.call(
              "ListDocuments",
              solvy.worker.ListDocumentsRequest.create(),
              {}
            );
            setDocuments(response.documents);
          } catch {
          } finally {
            setLoading(false);
          }
        },
      });
    }
    if (documentIsOpen && !docListMode) {
      docActions.push({
        name: "Close",
        disabled: unclearedBuffer,
        title: unclearedBuffer
          ? "Not all changes have been pushed to server."
          : undefined,
        async do() {
          setDocumentID(null);
          setProgram.tx("");
          setDocName.tx("");
        },
      });
    }
    if (program.current && !documentID) {
      docActions.push({
        name: "Save",
        disabled: false,
        async do() {
          const bd = Automerge.save(amDoc.current);
          try {
            setLoading(true);
            const response = await rpc.call(
              "CreateDocument",
              solvy.worker.CreateDocumentRequest.create({
                binary_document: bd,
              }),
              {}
            );
            setDocumentID(response.id);
          } catch {
          } finally {
            setLoading(false);
          }
        },
      });
    }
  }

  return [
    {
      documentOpen: !!documentID,
      documentName: docName.current,
      program: program.current,
      busy: loading,
      documents,
    },
    {
      program: setProgram.tx,
      name: setDocName.tx,
    },
    docActions,
    {
      async openDocument(id: string) {
        setLoading(true);
        try {
          const response = await rpc.call(
            "GetDocument",
            solvy.worker.GetDocumentRequest.create({ id }),
            {}
          );
          const bd = loadBinaryDocument<SolvyDocument>(
            response.binary_document
          );
          setAmDoc(bd);
          setDocumentID(id);
          setDocuments(void 0);
        } finally {
          setLoading(false);
        }
      },
      async deleteDocument(id: string) {
        setLoading(true);
        try {
          await rpc.call(
            "DeleteDocument",
            solvy.worker.DeleteDocumentRequest.create({ id }),
            {}
          );
          setDocumentID((currentDocID) => {
            if (currentDocID === id) {
              return null;
            }
            return currentDocID;
          });
          setDocuments((documents) => {
            return documents?.filter((d) => d.id !== id);
          });
        } finally {
          setLoading(false);
        }
      },
    },
  ];
}
