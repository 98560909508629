/*eslint-disable block-scoped-var, id-length, no-control-regex, no-magic-numbers, no-prototype-builtins, no-redeclare, no-shadow, no-var, sort-vars*/
import * as $protobuf from "protobufjs/minimal";

// Common aliases
const $Reader = $protobuf.Reader,
  $Writer = $protobuf.Writer,
  $util = $protobuf.util;

// Exported root namespace
const $root = $protobuf.roots["default"] || ($protobuf.roots["default"] = {});

export const solvy = ($root.solvy = (() => {
  /**
   * Namespace solvy.
   * @exports solvy
   * @namespace
   */
  const solvy = {};

  solvy.worker = (function () {
    /**
     * Namespace worker.
     * @memberof solvy
     * @namespace
     */
    const worker = {};

    worker.LoginRequest = (function () {
      /**
       * Properties of a LoginRequest.
       * @memberof solvy.worker
       * @interface ILoginRequest
       * @property {string|null} [auth0_access_token] LoginRequest auth0_access_token
       */

      /**
       * Constructs a new LoginRequest.
       * @memberof solvy.worker
       * @classdesc Represents a LoginRequest.
       * @implements ILoginRequest
       * @constructor
       * @param {solvy.worker.ILoginRequest=} [properties] Properties to set
       */
      function LoginRequest(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * LoginRequest auth0_access_token.
       * @member {string} auth0_access_token
       * @memberof solvy.worker.LoginRequest
       * @instance
       */
      LoginRequest.prototype.auth0_access_token = "";

      /**
       * Creates a new LoginRequest instance using the specified properties.
       * @function create
       * @memberof solvy.worker.LoginRequest
       * @static
       * @param {solvy.worker.ILoginRequest=} [properties] Properties to set
       * @returns {solvy.worker.LoginRequest} LoginRequest instance
       */
      LoginRequest.create = function create(properties) {
        return new LoginRequest(properties);
      };

      /**
       * Encodes the specified LoginRequest message. Does not implicitly {@link solvy.worker.LoginRequest.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.LoginRequest
       * @static
       * @param {solvy.worker.LoginRequest} message LoginRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      LoginRequest.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.auth0_access_token != null &&
          Object.hasOwnProperty.call(message, "auth0_access_token")
        )
          writer
            .uint32(/* id 1, wireType 2 =*/ 10)
            .string(message.auth0_access_token);
        return writer;
      };

      /**
       * Encodes the specified LoginRequest message, length delimited. Does not implicitly {@link solvy.worker.LoginRequest.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.LoginRequest
       * @static
       * @param {solvy.worker.LoginRequest} message LoginRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      LoginRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a LoginRequest message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.LoginRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.LoginRequest} LoginRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      LoginRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.LoginRequest();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1:
              message.auth0_access_token = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a LoginRequest message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.LoginRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.LoginRequest} LoginRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      LoginRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a LoginRequest message.
       * @function verify
       * @memberof solvy.worker.LoginRequest
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      LoginRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        if (
          message.auth0_access_token != null &&
          message.hasOwnProperty("auth0_access_token")
        )
          if (!$util.isString(message.auth0_access_token))
            return "auth0_access_token: string expected";
        return null;
      };

      /**
       * Creates a LoginRequest message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.LoginRequest
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.LoginRequest} LoginRequest
       */
      LoginRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.LoginRequest) return object;
        let message = new $root.solvy.worker.LoginRequest();
        if (object.auth0_access_token != null)
          message.auth0_access_token = String(object.auth0_access_token);
        return message;
      };

      /**
       * Creates a plain object from a LoginRequest message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.LoginRequest
       * @static
       * @param {solvy.worker.LoginRequest} message LoginRequest
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      LoginRequest.toObject = function toObject(message, options) {
        if (!options) options = {};
        let object = {};
        if (options.defaults) object.auth0_access_token = "";
        if (
          message.auth0_access_token != null &&
          message.hasOwnProperty("auth0_access_token")
        )
          object.auth0_access_token = message.auth0_access_token;
        return object;
      };

      /**
       * Converts this LoginRequest to JSON.
       * @function toJSON
       * @memberof solvy.worker.LoginRequest
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      LoginRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return LoginRequest;
    })();

    worker.LoginResponse = (function () {
      /**
       * Properties of a LoginResponse.
       * @memberof solvy.worker
       * @interface ILoginResponse
       */

      /**
       * Constructs a new LoginResponse.
       * @memberof solvy.worker
       * @classdesc Represents a LoginResponse.
       * @implements ILoginResponse
       * @constructor
       * @param {solvy.worker.ILoginResponse=} [properties] Properties to set
       */
      function LoginResponse(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * Creates a new LoginResponse instance using the specified properties.
       * @function create
       * @memberof solvy.worker.LoginResponse
       * @static
       * @param {solvy.worker.ILoginResponse=} [properties] Properties to set
       * @returns {solvy.worker.LoginResponse} LoginResponse instance
       */
      LoginResponse.create = function create(properties) {
        return new LoginResponse(properties);
      };

      /**
       * Encodes the specified LoginResponse message. Does not implicitly {@link solvy.worker.LoginResponse.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.LoginResponse
       * @static
       * @param {solvy.worker.LoginResponse} message LoginResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      LoginResponse.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        return writer;
      };

      /**
       * Encodes the specified LoginResponse message, length delimited. Does not implicitly {@link solvy.worker.LoginResponse.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.LoginResponse
       * @static
       * @param {solvy.worker.LoginResponse} message LoginResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      LoginResponse.encodeDelimited = function encodeDelimited(
        message,
        writer
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a LoginResponse message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.LoginResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.LoginResponse} LoginResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      LoginResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.LoginResponse();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a LoginResponse message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.LoginResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.LoginResponse} LoginResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      LoginResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a LoginResponse message.
       * @function verify
       * @memberof solvy.worker.LoginResponse
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      LoginResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        return null;
      };

      /**
       * Creates a LoginResponse message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.LoginResponse
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.LoginResponse} LoginResponse
       */
      LoginResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.LoginResponse) return object;
        return new $root.solvy.worker.LoginResponse();
      };

      /**
       * Creates a plain object from a LoginResponse message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.LoginResponse
       * @static
       * @param {solvy.worker.LoginResponse} message LoginResponse
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      LoginResponse.toObject = function toObject() {
        return {};
      };

      /**
       * Converts this LoginResponse to JSON.
       * @function toJSON
       * @memberof solvy.worker.LoginResponse
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      LoginResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return LoginResponse;
    })();

    worker.PingRequest = (function () {
      /**
       * Properties of a PingRequest.
       * @memberof solvy.worker
       * @interface IPingRequest
       */

      /**
       * Constructs a new PingRequest.
       * @memberof solvy.worker
       * @classdesc Represents a PingRequest.
       * @implements IPingRequest
       * @constructor
       * @param {solvy.worker.IPingRequest=} [properties] Properties to set
       */
      function PingRequest(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * Creates a new PingRequest instance using the specified properties.
       * @function create
       * @memberof solvy.worker.PingRequest
       * @static
       * @param {solvy.worker.IPingRequest=} [properties] Properties to set
       * @returns {solvy.worker.PingRequest} PingRequest instance
       */
      PingRequest.create = function create(properties) {
        return new PingRequest(properties);
      };

      /**
       * Encodes the specified PingRequest message. Does not implicitly {@link solvy.worker.PingRequest.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.PingRequest
       * @static
       * @param {solvy.worker.PingRequest} message PingRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      PingRequest.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        return writer;
      };

      /**
       * Encodes the specified PingRequest message, length delimited. Does not implicitly {@link solvy.worker.PingRequest.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.PingRequest
       * @static
       * @param {solvy.worker.PingRequest} message PingRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      PingRequest.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a PingRequest message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.PingRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.PingRequest} PingRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      PingRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.PingRequest();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a PingRequest message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.PingRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.PingRequest} PingRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      PingRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a PingRequest message.
       * @function verify
       * @memberof solvy.worker.PingRequest
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      PingRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        return null;
      };

      /**
       * Creates a PingRequest message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.PingRequest
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.PingRequest} PingRequest
       */
      PingRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.PingRequest) return object;
        return new $root.solvy.worker.PingRequest();
      };

      /**
       * Creates a plain object from a PingRequest message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.PingRequest
       * @static
       * @param {solvy.worker.PingRequest} message PingRequest
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      PingRequest.toObject = function toObject() {
        return {};
      };

      /**
       * Converts this PingRequest to JSON.
       * @function toJSON
       * @memberof solvy.worker.PingRequest
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      PingRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return PingRequest;
    })();

    worker.PingResponse = (function () {
      /**
       * Properties of a PingResponse.
       * @memberof solvy.worker
       * @interface IPingResponse
       * @property {string|null} [version] PingResponse version
       */

      /**
       * Constructs a new PingResponse.
       * @memberof solvy.worker
       * @classdesc Represents a PingResponse.
       * @implements IPingResponse
       * @constructor
       * @param {solvy.worker.IPingResponse=} [properties] Properties to set
       */
      function PingResponse(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * PingResponse version.
       * @member {string} version
       * @memberof solvy.worker.PingResponse
       * @instance
       */
      PingResponse.prototype.version = "";

      /**
       * Creates a new PingResponse instance using the specified properties.
       * @function create
       * @memberof solvy.worker.PingResponse
       * @static
       * @param {solvy.worker.IPingResponse=} [properties] Properties to set
       * @returns {solvy.worker.PingResponse} PingResponse instance
       */
      PingResponse.create = function create(properties) {
        return new PingResponse(properties);
      };

      /**
       * Encodes the specified PingResponse message. Does not implicitly {@link solvy.worker.PingResponse.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.PingResponse
       * @static
       * @param {solvy.worker.PingResponse} message PingResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      PingResponse.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.version != null &&
          Object.hasOwnProperty.call(message, "version")
        )
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.version);
        return writer;
      };

      /**
       * Encodes the specified PingResponse message, length delimited. Does not implicitly {@link solvy.worker.PingResponse.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.PingResponse
       * @static
       * @param {solvy.worker.PingResponse} message PingResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      PingResponse.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a PingResponse message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.PingResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.PingResponse} PingResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      PingResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.PingResponse();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1:
              message.version = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a PingResponse message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.PingResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.PingResponse} PingResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      PingResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a PingResponse message.
       * @function verify
       * @memberof solvy.worker.PingResponse
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      PingResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        if (message.version != null && message.hasOwnProperty("version"))
          if (!$util.isString(message.version))
            return "version: string expected";
        return null;
      };

      /**
       * Creates a PingResponse message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.PingResponse
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.PingResponse} PingResponse
       */
      PingResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.PingResponse) return object;
        let message = new $root.solvy.worker.PingResponse();
        if (object.version != null) message.version = String(object.version);
        return message;
      };

      /**
       * Creates a plain object from a PingResponse message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.PingResponse
       * @static
       * @param {solvy.worker.PingResponse} message PingResponse
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      PingResponse.toObject = function toObject(message, options) {
        if (!options) options = {};
        let object = {};
        if (options.defaults) object.version = "";
        if (message.version != null && message.hasOwnProperty("version"))
          object.version = message.version;
        return object;
      };

      /**
       * Converts this PingResponse to JSON.
       * @function toJSON
       * @memberof solvy.worker.PingResponse
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      PingResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return PingResponse;
    })();

    worker.Document = (function () {
      /**
       * Properties of a Document.
       * @memberof solvy.worker
       * @interface IDocument
       * @property {string|null} [id] Document id
       * @property {Uint8Array|null} [binary_document] Document binary_document
       */

      /**
       * Constructs a new Document.
       * @memberof solvy.worker
       * @classdesc Represents a Document.
       * @implements IDocument
       * @constructor
       * @param {solvy.worker.IDocument=} [properties] Properties to set
       */
      function Document(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * Document id.
       * @member {string} id
       * @memberof solvy.worker.Document
       * @instance
       */
      Document.prototype.id = "";

      /**
       * Document binary_document.
       * @member {Uint8Array} binary_document
       * @memberof solvy.worker.Document
       * @instance
       */
      Document.prototype.binary_document = $util.newBuffer([]);

      /**
       * Creates a new Document instance using the specified properties.
       * @function create
       * @memberof solvy.worker.Document
       * @static
       * @param {solvy.worker.IDocument=} [properties] Properties to set
       * @returns {solvy.worker.Document} Document instance
       */
      Document.create = function create(properties) {
        return new Document(properties);
      };

      /**
       * Encodes the specified Document message. Does not implicitly {@link solvy.worker.Document.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.Document
       * @static
       * @param {solvy.worker.Document} message Document message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      Document.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id);
        if (
          message.binary_document != null &&
          Object.hasOwnProperty.call(message, "binary_document")
        )
          writer
            .uint32(/* id 2, wireType 2 =*/ 18)
            .bytes(message.binary_document);
        return writer;
      };

      /**
       * Encodes the specified Document message, length delimited. Does not implicitly {@link solvy.worker.Document.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.Document
       * @static
       * @param {solvy.worker.Document} message Document message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      Document.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a Document message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.Document
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.Document} Document
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      Document.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.Document();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1:
              message.id = reader.string();
              break;
            case 2:
              message.binary_document = reader.bytes();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a Document message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.Document
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.Document} Document
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      Document.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a Document message.
       * @function verify
       * @memberof solvy.worker.Document
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      Document.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
          if (!$util.isString(message.id)) return "id: string expected";
        if (
          message.binary_document != null &&
          message.hasOwnProperty("binary_document")
        )
          if (
            !(
              (message.binary_document &&
                typeof message.binary_document.length === "number") ||
              $util.isString(message.binary_document)
            )
          )
            return "binary_document: buffer expected";
        return null;
      };

      /**
       * Creates a Document message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.Document
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.Document} Document
       */
      Document.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.Document) return object;
        let message = new $root.solvy.worker.Document();
        if (object.id != null) message.id = String(object.id);
        if (object.binary_document != null)
          if (typeof object.binary_document === "string")
            $util.base64.decode(
              object.binary_document,
              (message.binary_document = $util.newBuffer(
                $util.base64.length(object.binary_document)
              )),
              0
            );
          else if (object.binary_document.length)
            message.binary_document = object.binary_document;
        return message;
      };

      /**
       * Creates a plain object from a Document message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.Document
       * @static
       * @param {solvy.worker.Document} message Document
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      Document.toObject = function toObject(message, options) {
        if (!options) options = {};
        let object = {};
        if (options.defaults) {
          object.id = "";
          if (options.bytes === String) object.binary_document = "";
          else {
            object.binary_document = [];
            if (options.bytes !== Array)
              object.binary_document = $util.newBuffer(object.binary_document);
          }
        }
        if (message.id != null && message.hasOwnProperty("id"))
          object.id = message.id;
        if (
          message.binary_document != null &&
          message.hasOwnProperty("binary_document")
        )
          object.binary_document =
            options.bytes === String
              ? $util.base64.encode(
                  message.binary_document,
                  0,
                  message.binary_document.length
                )
              : options.bytes === Array
              ? Array.prototype.slice.call(message.binary_document)
              : message.binary_document;
        return object;
      };

      /**
       * Converts this Document to JSON.
       * @function toJSON
       * @memberof solvy.worker.Document
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      Document.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return Document;
    })();

    worker.DocumentInfo = (function () {
      /**
       * Properties of a DocumentInfo.
       * @memberof solvy.worker
       * @interface IDocumentInfo
       * @property {string|null} [id] DocumentInfo id
       * @property {string|null} [name] DocumentInfo name
       * @property {google.protobuf.Timestamp|null} [created_date] DocumentInfo created_date
       */

      /**
       * Constructs a new DocumentInfo.
       * @memberof solvy.worker
       * @classdesc Represents a DocumentInfo.
       * @implements IDocumentInfo
       * @constructor
       * @param {solvy.worker.IDocumentInfo=} [properties] Properties to set
       */
      function DocumentInfo(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * DocumentInfo id.
       * @member {string} id
       * @memberof solvy.worker.DocumentInfo
       * @instance
       */
      DocumentInfo.prototype.id = "";

      /**
       * DocumentInfo name.
       * @member {string} name
       * @memberof solvy.worker.DocumentInfo
       * @instance
       */
      DocumentInfo.prototype.name = "";

      /**
       * DocumentInfo created_date.
       * @member {google.protobuf.Timestamp|null|undefined} created_date
       * @memberof solvy.worker.DocumentInfo
       * @instance
       */
      DocumentInfo.prototype.created_date = null;

      /**
       * Creates a new DocumentInfo instance using the specified properties.
       * @function create
       * @memberof solvy.worker.DocumentInfo
       * @static
       * @param {solvy.worker.IDocumentInfo=} [properties] Properties to set
       * @returns {solvy.worker.DocumentInfo} DocumentInfo instance
       */
      DocumentInfo.create = function create(properties) {
        return new DocumentInfo(properties);
      };

      /**
       * Encodes the specified DocumentInfo message. Does not implicitly {@link solvy.worker.DocumentInfo.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.DocumentInfo
       * @static
       * @param {solvy.worker.DocumentInfo} message DocumentInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      DocumentInfo.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id);
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
          writer.uint32(/* id 2, wireType 2 =*/ 18).string(message.name);
        if (
          message.created_date != null &&
          Object.hasOwnProperty.call(message, "created_date")
        )
          $root.google.protobuf.Timestamp.encode(
            message.created_date,
            writer.uint32(/* id 3, wireType 2 =*/ 26).fork()
          ).ldelim();
        return writer;
      };

      /**
       * Encodes the specified DocumentInfo message, length delimited. Does not implicitly {@link solvy.worker.DocumentInfo.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.DocumentInfo
       * @static
       * @param {solvy.worker.DocumentInfo} message DocumentInfo message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      DocumentInfo.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a DocumentInfo message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.DocumentInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.DocumentInfo} DocumentInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      DocumentInfo.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.DocumentInfo();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1:
              message.id = reader.string();
              break;
            case 2:
              message.name = reader.string();
              break;
            case 3:
              message.created_date = $root.google.protobuf.Timestamp.decode(
                reader,
                reader.uint32()
              );
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a DocumentInfo message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.DocumentInfo
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.DocumentInfo} DocumentInfo
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      DocumentInfo.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a DocumentInfo message.
       * @function verify
       * @memberof solvy.worker.DocumentInfo
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      DocumentInfo.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
          if (!$util.isString(message.id)) return "id: string expected";
        if (message.name != null && message.hasOwnProperty("name"))
          if (!$util.isString(message.name)) return "name: string expected";
        if (
          message.created_date != null &&
          message.hasOwnProperty("created_date")
        ) {
          let error = $root.google.protobuf.Timestamp.verify(
            message.created_date
          );
          if (error) return "created_date." + error;
        }
        return null;
      };

      /**
       * Creates a DocumentInfo message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.DocumentInfo
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.DocumentInfo} DocumentInfo
       */
      DocumentInfo.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.DocumentInfo) return object;
        let message = new $root.solvy.worker.DocumentInfo();
        if (object.id != null) message.id = String(object.id);
        if (object.name != null) message.name = String(object.name);
        if (object.created_date != null) {
          if (typeof object.created_date !== "object")
            throw TypeError(
              ".solvy.worker.DocumentInfo.created_date: object expected"
            );
          message.created_date = $root.google.protobuf.Timestamp.fromObject(
            object.created_date
          );
        }
        return message;
      };

      /**
       * Creates a plain object from a DocumentInfo message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.DocumentInfo
       * @static
       * @param {solvy.worker.DocumentInfo} message DocumentInfo
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      DocumentInfo.toObject = function toObject(message, options) {
        if (!options) options = {};
        let object = {};
        if (options.defaults) {
          object.id = "";
          object.name = "";
          object.created_date = null;
        }
        if (message.id != null && message.hasOwnProperty("id"))
          object.id = message.id;
        if (message.name != null && message.hasOwnProperty("name"))
          object.name = message.name;
        if (
          message.created_date != null &&
          message.hasOwnProperty("created_date")
        )
          object.created_date = $root.google.protobuf.Timestamp.toObject(
            message.created_date,
            options
          );
        return object;
      };

      /**
       * Converts this DocumentInfo to JSON.
       * @function toJSON
       * @memberof solvy.worker.DocumentInfo
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      DocumentInfo.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return DocumentInfo;
    })();

    worker.ListDocumentsRequest = (function () {
      /**
       * Properties of a ListDocumentsRequest.
       * @memberof solvy.worker
       * @interface IListDocumentsRequest
       */

      /**
       * Constructs a new ListDocumentsRequest.
       * @memberof solvy.worker
       * @classdesc Represents a ListDocumentsRequest.
       * @implements IListDocumentsRequest
       * @constructor
       * @param {solvy.worker.IListDocumentsRequest=} [properties] Properties to set
       */
      function ListDocumentsRequest(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * Creates a new ListDocumentsRequest instance using the specified properties.
       * @function create
       * @memberof solvy.worker.ListDocumentsRequest
       * @static
       * @param {solvy.worker.IListDocumentsRequest=} [properties] Properties to set
       * @returns {solvy.worker.ListDocumentsRequest} ListDocumentsRequest instance
       */
      ListDocumentsRequest.create = function create(properties) {
        return new ListDocumentsRequest(properties);
      };

      /**
       * Encodes the specified ListDocumentsRequest message. Does not implicitly {@link solvy.worker.ListDocumentsRequest.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.ListDocumentsRequest
       * @static
       * @param {solvy.worker.ListDocumentsRequest} message ListDocumentsRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ListDocumentsRequest.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        return writer;
      };

      /**
       * Encodes the specified ListDocumentsRequest message, length delimited. Does not implicitly {@link solvy.worker.ListDocumentsRequest.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.ListDocumentsRequest
       * @static
       * @param {solvy.worker.ListDocumentsRequest} message ListDocumentsRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ListDocumentsRequest.encodeDelimited = function encodeDelimited(
        message,
        writer
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ListDocumentsRequest message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.ListDocumentsRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.ListDocumentsRequest} ListDocumentsRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ListDocumentsRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.ListDocumentsRequest();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a ListDocumentsRequest message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.ListDocumentsRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.ListDocumentsRequest} ListDocumentsRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ListDocumentsRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ListDocumentsRequest message.
       * @function verify
       * @memberof solvy.worker.ListDocumentsRequest
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ListDocumentsRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        return null;
      };

      /**
       * Creates a ListDocumentsRequest message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.ListDocumentsRequest
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.ListDocumentsRequest} ListDocumentsRequest
       */
      ListDocumentsRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.ListDocumentsRequest)
          return object;
        return new $root.solvy.worker.ListDocumentsRequest();
      };

      /**
       * Creates a plain object from a ListDocumentsRequest message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.ListDocumentsRequest
       * @static
       * @param {solvy.worker.ListDocumentsRequest} message ListDocumentsRequest
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ListDocumentsRequest.toObject = function toObject() {
        return {};
      };

      /**
       * Converts this ListDocumentsRequest to JSON.
       * @function toJSON
       * @memberof solvy.worker.ListDocumentsRequest
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ListDocumentsRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return ListDocumentsRequest;
    })();

    worker.ListDocumentsResponse = (function () {
      /**
       * Properties of a ListDocumentsResponse.
       * @memberof solvy.worker
       * @interface IListDocumentsResponse
       * @property {Array.<solvy.worker.DocumentInfo>|null} [documents] ListDocumentsResponse documents
       */

      /**
       * Constructs a new ListDocumentsResponse.
       * @memberof solvy.worker
       * @classdesc Represents a ListDocumentsResponse.
       * @implements IListDocumentsResponse
       * @constructor
       * @param {solvy.worker.IListDocumentsResponse=} [properties] Properties to set
       */
      function ListDocumentsResponse(properties) {
        this.documents = [];
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * ListDocumentsResponse documents.
       * @member {Array.<solvy.worker.DocumentInfo>} documents
       * @memberof solvy.worker.ListDocumentsResponse
       * @instance
       */
      ListDocumentsResponse.prototype.documents = $util.emptyArray;

      /**
       * Creates a new ListDocumentsResponse instance using the specified properties.
       * @function create
       * @memberof solvy.worker.ListDocumentsResponse
       * @static
       * @param {solvy.worker.IListDocumentsResponse=} [properties] Properties to set
       * @returns {solvy.worker.ListDocumentsResponse} ListDocumentsResponse instance
       */
      ListDocumentsResponse.create = function create(properties) {
        return new ListDocumentsResponse(properties);
      };

      /**
       * Encodes the specified ListDocumentsResponse message. Does not implicitly {@link solvy.worker.ListDocumentsResponse.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.ListDocumentsResponse
       * @static
       * @param {solvy.worker.ListDocumentsResponse} message ListDocumentsResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ListDocumentsResponse.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.documents != null && message.documents.length)
          for (let i = 0; i < message.documents.length; ++i)
            $root.solvy.worker.DocumentInfo.encode(
              message.documents[i],
              writer.uint32(/* id 1, wireType 2 =*/ 10).fork()
            ).ldelim();
        return writer;
      };

      /**
       * Encodes the specified ListDocumentsResponse message, length delimited. Does not implicitly {@link solvy.worker.ListDocumentsResponse.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.ListDocumentsResponse
       * @static
       * @param {solvy.worker.ListDocumentsResponse} message ListDocumentsResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      ListDocumentsResponse.encodeDelimited = function encodeDelimited(
        message,
        writer
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a ListDocumentsResponse message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.ListDocumentsResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.ListDocumentsResponse} ListDocumentsResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ListDocumentsResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.ListDocumentsResponse();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1:
              if (!(message.documents && message.documents.length))
                message.documents = [];
              message.documents.push(
                $root.solvy.worker.DocumentInfo.decode(reader, reader.uint32())
              );
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a ListDocumentsResponse message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.ListDocumentsResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.ListDocumentsResponse} ListDocumentsResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      ListDocumentsResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a ListDocumentsResponse message.
       * @function verify
       * @memberof solvy.worker.ListDocumentsResponse
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      ListDocumentsResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        if (message.documents != null && message.hasOwnProperty("documents")) {
          if (!Array.isArray(message.documents))
            return "documents: array expected";
          for (let i = 0; i < message.documents.length; ++i) {
            let error = $root.solvy.worker.DocumentInfo.verify(
              message.documents[i]
            );
            if (error) return "documents." + error;
          }
        }
        return null;
      };

      /**
       * Creates a ListDocumentsResponse message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.ListDocumentsResponse
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.ListDocumentsResponse} ListDocumentsResponse
       */
      ListDocumentsResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.ListDocumentsResponse)
          return object;
        let message = new $root.solvy.worker.ListDocumentsResponse();
        if (object.documents) {
          if (!Array.isArray(object.documents))
            throw TypeError(
              ".solvy.worker.ListDocumentsResponse.documents: array expected"
            );
          message.documents = [];
          for (let i = 0; i < object.documents.length; ++i) {
            if (typeof object.documents[i] !== "object")
              throw TypeError(
                ".solvy.worker.ListDocumentsResponse.documents: object expected"
              );
            message.documents[i] = $root.solvy.worker.DocumentInfo.fromObject(
              object.documents[i]
            );
          }
        }
        return message;
      };

      /**
       * Creates a plain object from a ListDocumentsResponse message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.ListDocumentsResponse
       * @static
       * @param {solvy.worker.ListDocumentsResponse} message ListDocumentsResponse
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      ListDocumentsResponse.toObject = function toObject(message, options) {
        if (!options) options = {};
        let object = {};
        if (options.arrays || options.defaults) object.documents = [];
        if (message.documents && message.documents.length) {
          object.documents = [];
          for (let j = 0; j < message.documents.length; ++j)
            object.documents[j] = $root.solvy.worker.DocumentInfo.toObject(
              message.documents[j],
              options
            );
        }
        return object;
      };

      /**
       * Converts this ListDocumentsResponse to JSON.
       * @function toJSON
       * @memberof solvy.worker.ListDocumentsResponse
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      ListDocumentsResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return ListDocumentsResponse;
    })();

    worker.CreateDocumentRequest = (function () {
      /**
       * Properties of a CreateDocumentRequest.
       * @memberof solvy.worker
       * @interface ICreateDocumentRequest
       * @property {Uint8Array|null} [binary_document] CreateDocumentRequest binary_document
       */

      /**
       * Constructs a new CreateDocumentRequest.
       * @memberof solvy.worker
       * @classdesc Represents a CreateDocumentRequest.
       * @implements ICreateDocumentRequest
       * @constructor
       * @param {solvy.worker.ICreateDocumentRequest=} [properties] Properties to set
       */
      function CreateDocumentRequest(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * CreateDocumentRequest binary_document.
       * @member {Uint8Array} binary_document
       * @memberof solvy.worker.CreateDocumentRequest
       * @instance
       */
      CreateDocumentRequest.prototype.binary_document = $util.newBuffer([]);

      /**
       * Creates a new CreateDocumentRequest instance using the specified properties.
       * @function create
       * @memberof solvy.worker.CreateDocumentRequest
       * @static
       * @param {solvy.worker.ICreateDocumentRequest=} [properties] Properties to set
       * @returns {solvy.worker.CreateDocumentRequest} CreateDocumentRequest instance
       */
      CreateDocumentRequest.create = function create(properties) {
        return new CreateDocumentRequest(properties);
      };

      /**
       * Encodes the specified CreateDocumentRequest message. Does not implicitly {@link solvy.worker.CreateDocumentRequest.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.CreateDocumentRequest
       * @static
       * @param {solvy.worker.CreateDocumentRequest} message CreateDocumentRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      CreateDocumentRequest.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.binary_document != null &&
          Object.hasOwnProperty.call(message, "binary_document")
        )
          writer
            .uint32(/* id 1, wireType 2 =*/ 10)
            .bytes(message.binary_document);
        return writer;
      };

      /**
       * Encodes the specified CreateDocumentRequest message, length delimited. Does not implicitly {@link solvy.worker.CreateDocumentRequest.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.CreateDocumentRequest
       * @static
       * @param {solvy.worker.CreateDocumentRequest} message CreateDocumentRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      CreateDocumentRequest.encodeDelimited = function encodeDelimited(
        message,
        writer
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a CreateDocumentRequest message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.CreateDocumentRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.CreateDocumentRequest} CreateDocumentRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      CreateDocumentRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.CreateDocumentRequest();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1:
              message.binary_document = reader.bytes();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a CreateDocumentRequest message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.CreateDocumentRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.CreateDocumentRequest} CreateDocumentRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      CreateDocumentRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a CreateDocumentRequest message.
       * @function verify
       * @memberof solvy.worker.CreateDocumentRequest
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      CreateDocumentRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        if (
          message.binary_document != null &&
          message.hasOwnProperty("binary_document")
        )
          if (
            !(
              (message.binary_document &&
                typeof message.binary_document.length === "number") ||
              $util.isString(message.binary_document)
            )
          )
            return "binary_document: buffer expected";
        return null;
      };

      /**
       * Creates a CreateDocumentRequest message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.CreateDocumentRequest
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.CreateDocumentRequest} CreateDocumentRequest
       */
      CreateDocumentRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.CreateDocumentRequest)
          return object;
        let message = new $root.solvy.worker.CreateDocumentRequest();
        if (object.binary_document != null)
          if (typeof object.binary_document === "string")
            $util.base64.decode(
              object.binary_document,
              (message.binary_document = $util.newBuffer(
                $util.base64.length(object.binary_document)
              )),
              0
            );
          else if (object.binary_document.length)
            message.binary_document = object.binary_document;
        return message;
      };

      /**
       * Creates a plain object from a CreateDocumentRequest message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.CreateDocumentRequest
       * @static
       * @param {solvy.worker.CreateDocumentRequest} message CreateDocumentRequest
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      CreateDocumentRequest.toObject = function toObject(message, options) {
        if (!options) options = {};
        let object = {};
        if (options.defaults)
          if (options.bytes === String) object.binary_document = "";
          else {
            object.binary_document = [];
            if (options.bytes !== Array)
              object.binary_document = $util.newBuffer(object.binary_document);
          }
        if (
          message.binary_document != null &&
          message.hasOwnProperty("binary_document")
        )
          object.binary_document =
            options.bytes === String
              ? $util.base64.encode(
                  message.binary_document,
                  0,
                  message.binary_document.length
                )
              : options.bytes === Array
              ? Array.prototype.slice.call(message.binary_document)
              : message.binary_document;
        return object;
      };

      /**
       * Converts this CreateDocumentRequest to JSON.
       * @function toJSON
       * @memberof solvy.worker.CreateDocumentRequest
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      CreateDocumentRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return CreateDocumentRequest;
    })();

    worker.CreateDocumentResponse = (function () {
      /**
       * Properties of a CreateDocumentResponse.
       * @memberof solvy.worker
       * @interface ICreateDocumentResponse
       * @property {string|null} [id] CreateDocumentResponse id
       */

      /**
       * Constructs a new CreateDocumentResponse.
       * @memberof solvy.worker
       * @classdesc Represents a CreateDocumentResponse.
       * @implements ICreateDocumentResponse
       * @constructor
       * @param {solvy.worker.ICreateDocumentResponse=} [properties] Properties to set
       */
      function CreateDocumentResponse(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * CreateDocumentResponse id.
       * @member {string} id
       * @memberof solvy.worker.CreateDocumentResponse
       * @instance
       */
      CreateDocumentResponse.prototype.id = "";

      /**
       * Creates a new CreateDocumentResponse instance using the specified properties.
       * @function create
       * @memberof solvy.worker.CreateDocumentResponse
       * @static
       * @param {solvy.worker.ICreateDocumentResponse=} [properties] Properties to set
       * @returns {solvy.worker.CreateDocumentResponse} CreateDocumentResponse instance
       */
      CreateDocumentResponse.create = function create(properties) {
        return new CreateDocumentResponse(properties);
      };

      /**
       * Encodes the specified CreateDocumentResponse message. Does not implicitly {@link solvy.worker.CreateDocumentResponse.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.CreateDocumentResponse
       * @static
       * @param {solvy.worker.CreateDocumentResponse} message CreateDocumentResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      CreateDocumentResponse.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id);
        return writer;
      };

      /**
       * Encodes the specified CreateDocumentResponse message, length delimited. Does not implicitly {@link solvy.worker.CreateDocumentResponse.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.CreateDocumentResponse
       * @static
       * @param {solvy.worker.CreateDocumentResponse} message CreateDocumentResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      CreateDocumentResponse.encodeDelimited = function encodeDelimited(
        message,
        writer
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a CreateDocumentResponse message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.CreateDocumentResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.CreateDocumentResponse} CreateDocumentResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      CreateDocumentResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.CreateDocumentResponse();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1:
              message.id = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a CreateDocumentResponse message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.CreateDocumentResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.CreateDocumentResponse} CreateDocumentResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      CreateDocumentResponse.decodeDelimited = function decodeDelimited(
        reader
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a CreateDocumentResponse message.
       * @function verify
       * @memberof solvy.worker.CreateDocumentResponse
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      CreateDocumentResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
          if (!$util.isString(message.id)) return "id: string expected";
        return null;
      };

      /**
       * Creates a CreateDocumentResponse message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.CreateDocumentResponse
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.CreateDocumentResponse} CreateDocumentResponse
       */
      CreateDocumentResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.CreateDocumentResponse)
          return object;
        let message = new $root.solvy.worker.CreateDocumentResponse();
        if (object.id != null) message.id = String(object.id);
        return message;
      };

      /**
       * Creates a plain object from a CreateDocumentResponse message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.CreateDocumentResponse
       * @static
       * @param {solvy.worker.CreateDocumentResponse} message CreateDocumentResponse
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      CreateDocumentResponse.toObject = function toObject(message, options) {
        if (!options) options = {};
        let object = {};
        if (options.defaults) object.id = "";
        if (message.id != null && message.hasOwnProperty("id"))
          object.id = message.id;
        return object;
      };

      /**
       * Converts this CreateDocumentResponse to JSON.
       * @function toJSON
       * @memberof solvy.worker.CreateDocumentResponse
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      CreateDocumentResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return CreateDocumentResponse;
    })();

    worker.PushDocumentChangeRequest = (function () {
      /**
       * Properties of a PushDocumentChangeRequest.
       * @memberof solvy.worker
       * @interface IPushDocumentChangeRequest
       * @property {string|null} [id] PushDocumentChangeRequest id
       * @property {Array.<Uint8Array>|null} [binary_change] PushDocumentChangeRequest binary_change
       */

      /**
       * Constructs a new PushDocumentChangeRequest.
       * @memberof solvy.worker
       * @classdesc Represents a PushDocumentChangeRequest.
       * @implements IPushDocumentChangeRequest
       * @constructor
       * @param {solvy.worker.IPushDocumentChangeRequest=} [properties] Properties to set
       */
      function PushDocumentChangeRequest(properties) {
        this.binary_change = [];
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * PushDocumentChangeRequest id.
       * @member {string} id
       * @memberof solvy.worker.PushDocumentChangeRequest
       * @instance
       */
      PushDocumentChangeRequest.prototype.id = "";

      /**
       * PushDocumentChangeRequest binary_change.
       * @member {Array.<Uint8Array>} binary_change
       * @memberof solvy.worker.PushDocumentChangeRequest
       * @instance
       */
      PushDocumentChangeRequest.prototype.binary_change = $util.emptyArray;

      /**
       * Creates a new PushDocumentChangeRequest instance using the specified properties.
       * @function create
       * @memberof solvy.worker.PushDocumentChangeRequest
       * @static
       * @param {solvy.worker.IPushDocumentChangeRequest=} [properties] Properties to set
       * @returns {solvy.worker.PushDocumentChangeRequest} PushDocumentChangeRequest instance
       */
      PushDocumentChangeRequest.create = function create(properties) {
        return new PushDocumentChangeRequest(properties);
      };

      /**
       * Encodes the specified PushDocumentChangeRequest message. Does not implicitly {@link solvy.worker.PushDocumentChangeRequest.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.PushDocumentChangeRequest
       * @static
       * @param {solvy.worker.PushDocumentChangeRequest} message PushDocumentChangeRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      PushDocumentChangeRequest.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id);
        if (message.binary_change != null && message.binary_change.length)
          for (let i = 0; i < message.binary_change.length; ++i)
            writer
              .uint32(/* id 2, wireType 2 =*/ 18)
              .bytes(message.binary_change[i]);
        return writer;
      };

      /**
       * Encodes the specified PushDocumentChangeRequest message, length delimited. Does not implicitly {@link solvy.worker.PushDocumentChangeRequest.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.PushDocumentChangeRequest
       * @static
       * @param {solvy.worker.PushDocumentChangeRequest} message PushDocumentChangeRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      PushDocumentChangeRequest.encodeDelimited = function encodeDelimited(
        message,
        writer
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a PushDocumentChangeRequest message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.PushDocumentChangeRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.PushDocumentChangeRequest} PushDocumentChangeRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      PushDocumentChangeRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.PushDocumentChangeRequest();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1:
              message.id = reader.string();
              break;
            case 2:
              if (!(message.binary_change && message.binary_change.length))
                message.binary_change = [];
              message.binary_change.push(reader.bytes());
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a PushDocumentChangeRequest message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.PushDocumentChangeRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.PushDocumentChangeRequest} PushDocumentChangeRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      PushDocumentChangeRequest.decodeDelimited = function decodeDelimited(
        reader
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a PushDocumentChangeRequest message.
       * @function verify
       * @memberof solvy.worker.PushDocumentChangeRequest
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      PushDocumentChangeRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
          if (!$util.isString(message.id)) return "id: string expected";
        if (
          message.binary_change != null &&
          message.hasOwnProperty("binary_change")
        ) {
          if (!Array.isArray(message.binary_change))
            return "binary_change: array expected";
          for (let i = 0; i < message.binary_change.length; ++i)
            if (
              !(
                (message.binary_change[i] &&
                  typeof message.binary_change[i].length === "number") ||
                $util.isString(message.binary_change[i])
              )
            )
              return "binary_change: buffer[] expected";
        }
        return null;
      };

      /**
       * Creates a PushDocumentChangeRequest message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.PushDocumentChangeRequest
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.PushDocumentChangeRequest} PushDocumentChangeRequest
       */
      PushDocumentChangeRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.PushDocumentChangeRequest)
          return object;
        let message = new $root.solvy.worker.PushDocumentChangeRequest();
        if (object.id != null) message.id = String(object.id);
        if (object.binary_change) {
          if (!Array.isArray(object.binary_change))
            throw TypeError(
              ".solvy.worker.PushDocumentChangeRequest.binary_change: array expected"
            );
          message.binary_change = [];
          for (let i = 0; i < object.binary_change.length; ++i)
            if (typeof object.binary_change[i] === "string")
              $util.base64.decode(
                object.binary_change[i],
                (message.binary_change[i] = $util.newBuffer(
                  $util.base64.length(object.binary_change[i])
                )),
                0
              );
            else if (object.binary_change[i].length)
              message.binary_change[i] = object.binary_change[i];
        }
        return message;
      };

      /**
       * Creates a plain object from a PushDocumentChangeRequest message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.PushDocumentChangeRequest
       * @static
       * @param {solvy.worker.PushDocumentChangeRequest} message PushDocumentChangeRequest
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      PushDocumentChangeRequest.toObject = function toObject(message, options) {
        if (!options) options = {};
        let object = {};
        if (options.arrays || options.defaults) object.binary_change = [];
        if (options.defaults) object.id = "";
        if (message.id != null && message.hasOwnProperty("id"))
          object.id = message.id;
        if (message.binary_change && message.binary_change.length) {
          object.binary_change = [];
          for (let j = 0; j < message.binary_change.length; ++j)
            object.binary_change[j] =
              options.bytes === String
                ? $util.base64.encode(
                    message.binary_change[j],
                    0,
                    message.binary_change[j].length
                  )
                : options.bytes === Array
                ? Array.prototype.slice.call(message.binary_change[j])
                : message.binary_change[j];
        }
        return object;
      };

      /**
       * Converts this PushDocumentChangeRequest to JSON.
       * @function toJSON
       * @memberof solvy.worker.PushDocumentChangeRequest
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      PushDocumentChangeRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return PushDocumentChangeRequest;
    })();

    worker.PushDocumentChangeResponse = (function () {
      /**
       * Properties of a PushDocumentChangeResponse.
       * @memberof solvy.worker
       * @interface IPushDocumentChangeResponse
       */

      /**
       * Constructs a new PushDocumentChangeResponse.
       * @memberof solvy.worker
       * @classdesc Represents a PushDocumentChangeResponse.
       * @implements IPushDocumentChangeResponse
       * @constructor
       * @param {solvy.worker.IPushDocumentChangeResponse=} [properties] Properties to set
       */
      function PushDocumentChangeResponse(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * Creates a new PushDocumentChangeResponse instance using the specified properties.
       * @function create
       * @memberof solvy.worker.PushDocumentChangeResponse
       * @static
       * @param {solvy.worker.IPushDocumentChangeResponse=} [properties] Properties to set
       * @returns {solvy.worker.PushDocumentChangeResponse} PushDocumentChangeResponse instance
       */
      PushDocumentChangeResponse.create = function create(properties) {
        return new PushDocumentChangeResponse(properties);
      };

      /**
       * Encodes the specified PushDocumentChangeResponse message. Does not implicitly {@link solvy.worker.PushDocumentChangeResponse.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.PushDocumentChangeResponse
       * @static
       * @param {solvy.worker.PushDocumentChangeResponse} message PushDocumentChangeResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      PushDocumentChangeResponse.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        return writer;
      };

      /**
       * Encodes the specified PushDocumentChangeResponse message, length delimited. Does not implicitly {@link solvy.worker.PushDocumentChangeResponse.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.PushDocumentChangeResponse
       * @static
       * @param {solvy.worker.PushDocumentChangeResponse} message PushDocumentChangeResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      PushDocumentChangeResponse.encodeDelimited = function encodeDelimited(
        message,
        writer
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a PushDocumentChangeResponse message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.PushDocumentChangeResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.PushDocumentChangeResponse} PushDocumentChangeResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      PushDocumentChangeResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.PushDocumentChangeResponse();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a PushDocumentChangeResponse message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.PushDocumentChangeResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.PushDocumentChangeResponse} PushDocumentChangeResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      PushDocumentChangeResponse.decodeDelimited = function decodeDelimited(
        reader
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a PushDocumentChangeResponse message.
       * @function verify
       * @memberof solvy.worker.PushDocumentChangeResponse
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      PushDocumentChangeResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        return null;
      };

      /**
       * Creates a PushDocumentChangeResponse message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.PushDocumentChangeResponse
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.PushDocumentChangeResponse} PushDocumentChangeResponse
       */
      PushDocumentChangeResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.PushDocumentChangeResponse)
          return object;
        return new $root.solvy.worker.PushDocumentChangeResponse();
      };

      /**
       * Creates a plain object from a PushDocumentChangeResponse message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.PushDocumentChangeResponse
       * @static
       * @param {solvy.worker.PushDocumentChangeResponse} message PushDocumentChangeResponse
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      PushDocumentChangeResponse.toObject = function toObject() {
        return {};
      };

      /**
       * Converts this PushDocumentChangeResponse to JSON.
       * @function toJSON
       * @memberof solvy.worker.PushDocumentChangeResponse
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      PushDocumentChangeResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return PushDocumentChangeResponse;
    })();

    worker.GetDocumentInfoRequest = (function () {
      /**
       * Properties of a GetDocumentInfoRequest.
       * @memberof solvy.worker
       * @interface IGetDocumentInfoRequest
       */

      /**
       * Constructs a new GetDocumentInfoRequest.
       * @memberof solvy.worker
       * @classdesc Represents a GetDocumentInfoRequest.
       * @implements IGetDocumentInfoRequest
       * @constructor
       * @param {solvy.worker.IGetDocumentInfoRequest=} [properties] Properties to set
       */
      function GetDocumentInfoRequest(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * Creates a new GetDocumentInfoRequest instance using the specified properties.
       * @function create
       * @memberof solvy.worker.GetDocumentInfoRequest
       * @static
       * @param {solvy.worker.IGetDocumentInfoRequest=} [properties] Properties to set
       * @returns {solvy.worker.GetDocumentInfoRequest} GetDocumentInfoRequest instance
       */
      GetDocumentInfoRequest.create = function create(properties) {
        return new GetDocumentInfoRequest(properties);
      };

      /**
       * Encodes the specified GetDocumentInfoRequest message. Does not implicitly {@link solvy.worker.GetDocumentInfoRequest.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.GetDocumentInfoRequest
       * @static
       * @param {solvy.worker.GetDocumentInfoRequest} message GetDocumentInfoRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      GetDocumentInfoRequest.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        return writer;
      };

      /**
       * Encodes the specified GetDocumentInfoRequest message, length delimited. Does not implicitly {@link solvy.worker.GetDocumentInfoRequest.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.GetDocumentInfoRequest
       * @static
       * @param {solvy.worker.GetDocumentInfoRequest} message GetDocumentInfoRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      GetDocumentInfoRequest.encodeDelimited = function encodeDelimited(
        message,
        writer
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a GetDocumentInfoRequest message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.GetDocumentInfoRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.GetDocumentInfoRequest} GetDocumentInfoRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      GetDocumentInfoRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.GetDocumentInfoRequest();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a GetDocumentInfoRequest message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.GetDocumentInfoRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.GetDocumentInfoRequest} GetDocumentInfoRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      GetDocumentInfoRequest.decodeDelimited = function decodeDelimited(
        reader
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a GetDocumentInfoRequest message.
       * @function verify
       * @memberof solvy.worker.GetDocumentInfoRequest
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      GetDocumentInfoRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        return null;
      };

      /**
       * Creates a GetDocumentInfoRequest message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.GetDocumentInfoRequest
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.GetDocumentInfoRequest} GetDocumentInfoRequest
       */
      GetDocumentInfoRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.GetDocumentInfoRequest)
          return object;
        return new $root.solvy.worker.GetDocumentInfoRequest();
      };

      /**
       * Creates a plain object from a GetDocumentInfoRequest message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.GetDocumentInfoRequest
       * @static
       * @param {solvy.worker.GetDocumentInfoRequest} message GetDocumentInfoRequest
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      GetDocumentInfoRequest.toObject = function toObject() {
        return {};
      };

      /**
       * Converts this GetDocumentInfoRequest to JSON.
       * @function toJSON
       * @memberof solvy.worker.GetDocumentInfoRequest
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      GetDocumentInfoRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return GetDocumentInfoRequest;
    })();

    worker.GetDocumentInfoResponse = (function () {
      /**
       * Properties of a GetDocumentInfoResponse.
       * @memberof solvy.worker
       * @interface IGetDocumentInfoResponse
       * @property {string|null} [name] GetDocumentInfoResponse name
       * @property {google.protobuf.Timestamp|null} [created_date] GetDocumentInfoResponse created_date
       */

      /**
       * Constructs a new GetDocumentInfoResponse.
       * @memberof solvy.worker
       * @classdesc Represents a GetDocumentInfoResponse.
       * @implements IGetDocumentInfoResponse
       * @constructor
       * @param {solvy.worker.IGetDocumentInfoResponse=} [properties] Properties to set
       */
      function GetDocumentInfoResponse(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * GetDocumentInfoResponse name.
       * @member {string} name
       * @memberof solvy.worker.GetDocumentInfoResponse
       * @instance
       */
      GetDocumentInfoResponse.prototype.name = "";

      /**
       * GetDocumentInfoResponse created_date.
       * @member {google.protobuf.Timestamp|null|undefined} created_date
       * @memberof solvy.worker.GetDocumentInfoResponse
       * @instance
       */
      GetDocumentInfoResponse.prototype.created_date = null;

      /**
       * Creates a new GetDocumentInfoResponse instance using the specified properties.
       * @function create
       * @memberof solvy.worker.GetDocumentInfoResponse
       * @static
       * @param {solvy.worker.IGetDocumentInfoResponse=} [properties] Properties to set
       * @returns {solvy.worker.GetDocumentInfoResponse} GetDocumentInfoResponse instance
       */
      GetDocumentInfoResponse.create = function create(properties) {
        return new GetDocumentInfoResponse(properties);
      };

      /**
       * Encodes the specified GetDocumentInfoResponse message. Does not implicitly {@link solvy.worker.GetDocumentInfoResponse.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.GetDocumentInfoResponse
       * @static
       * @param {solvy.worker.GetDocumentInfoResponse} message GetDocumentInfoResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      GetDocumentInfoResponse.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.name != null && Object.hasOwnProperty.call(message, "name"))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.name);
        if (
          message.created_date != null &&
          Object.hasOwnProperty.call(message, "created_date")
        )
          $root.google.protobuf.Timestamp.encode(
            message.created_date,
            writer.uint32(/* id 2, wireType 2 =*/ 18).fork()
          ).ldelim();
        return writer;
      };

      /**
       * Encodes the specified GetDocumentInfoResponse message, length delimited. Does not implicitly {@link solvy.worker.GetDocumentInfoResponse.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.GetDocumentInfoResponse
       * @static
       * @param {solvy.worker.GetDocumentInfoResponse} message GetDocumentInfoResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      GetDocumentInfoResponse.encodeDelimited = function encodeDelimited(
        message,
        writer
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a GetDocumentInfoResponse message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.GetDocumentInfoResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.GetDocumentInfoResponse} GetDocumentInfoResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      GetDocumentInfoResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.GetDocumentInfoResponse();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1:
              message.name = reader.string();
              break;
            case 2:
              message.created_date = $root.google.protobuf.Timestamp.decode(
                reader,
                reader.uint32()
              );
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a GetDocumentInfoResponse message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.GetDocumentInfoResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.GetDocumentInfoResponse} GetDocumentInfoResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      GetDocumentInfoResponse.decodeDelimited = function decodeDelimited(
        reader
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a GetDocumentInfoResponse message.
       * @function verify
       * @memberof solvy.worker.GetDocumentInfoResponse
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      GetDocumentInfoResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        if (message.name != null && message.hasOwnProperty("name"))
          if (!$util.isString(message.name)) return "name: string expected";
        if (
          message.created_date != null &&
          message.hasOwnProperty("created_date")
        ) {
          let error = $root.google.protobuf.Timestamp.verify(
            message.created_date
          );
          if (error) return "created_date." + error;
        }
        return null;
      };

      /**
       * Creates a GetDocumentInfoResponse message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.GetDocumentInfoResponse
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.GetDocumentInfoResponse} GetDocumentInfoResponse
       */
      GetDocumentInfoResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.GetDocumentInfoResponse)
          return object;
        let message = new $root.solvy.worker.GetDocumentInfoResponse();
        if (object.name != null) message.name = String(object.name);
        if (object.created_date != null) {
          if (typeof object.created_date !== "object")
            throw TypeError(
              ".solvy.worker.GetDocumentInfoResponse.created_date: object expected"
            );
          message.created_date = $root.google.protobuf.Timestamp.fromObject(
            object.created_date
          );
        }
        return message;
      };

      /**
       * Creates a plain object from a GetDocumentInfoResponse message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.GetDocumentInfoResponse
       * @static
       * @param {solvy.worker.GetDocumentInfoResponse} message GetDocumentInfoResponse
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      GetDocumentInfoResponse.toObject = function toObject(message, options) {
        if (!options) options = {};
        let object = {};
        if (options.defaults) {
          object.name = "";
          object.created_date = null;
        }
        if (message.name != null && message.hasOwnProperty("name"))
          object.name = message.name;
        if (
          message.created_date != null &&
          message.hasOwnProperty("created_date")
        )
          object.created_date = $root.google.protobuf.Timestamp.toObject(
            message.created_date,
            options
          );
        return object;
      };

      /**
       * Converts this GetDocumentInfoResponse to JSON.
       * @function toJSON
       * @memberof solvy.worker.GetDocumentInfoResponse
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      GetDocumentInfoResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return GetDocumentInfoResponse;
    })();

    worker.GetDocumentRequest = (function () {
      /**
       * Properties of a GetDocumentRequest.
       * @memberof solvy.worker
       * @interface IGetDocumentRequest
       * @property {string|null} [id] GetDocumentRequest id
       */

      /**
       * Constructs a new GetDocumentRequest.
       * @memberof solvy.worker
       * @classdesc Represents a GetDocumentRequest.
       * @implements IGetDocumentRequest
       * @constructor
       * @param {solvy.worker.IGetDocumentRequest=} [properties] Properties to set
       */
      function GetDocumentRequest(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * GetDocumentRequest id.
       * @member {string} id
       * @memberof solvy.worker.GetDocumentRequest
       * @instance
       */
      GetDocumentRequest.prototype.id = "";

      /**
       * Creates a new GetDocumentRequest instance using the specified properties.
       * @function create
       * @memberof solvy.worker.GetDocumentRequest
       * @static
       * @param {solvy.worker.IGetDocumentRequest=} [properties] Properties to set
       * @returns {solvy.worker.GetDocumentRequest} GetDocumentRequest instance
       */
      GetDocumentRequest.create = function create(properties) {
        return new GetDocumentRequest(properties);
      };

      /**
       * Encodes the specified GetDocumentRequest message. Does not implicitly {@link solvy.worker.GetDocumentRequest.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.GetDocumentRequest
       * @static
       * @param {solvy.worker.GetDocumentRequest} message GetDocumentRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      GetDocumentRequest.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id);
        return writer;
      };

      /**
       * Encodes the specified GetDocumentRequest message, length delimited. Does not implicitly {@link solvy.worker.GetDocumentRequest.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.GetDocumentRequest
       * @static
       * @param {solvy.worker.GetDocumentRequest} message GetDocumentRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      GetDocumentRequest.encodeDelimited = function encodeDelimited(
        message,
        writer
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a GetDocumentRequest message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.GetDocumentRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.GetDocumentRequest} GetDocumentRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      GetDocumentRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.GetDocumentRequest();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1:
              message.id = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a GetDocumentRequest message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.GetDocumentRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.GetDocumentRequest} GetDocumentRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      GetDocumentRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a GetDocumentRequest message.
       * @function verify
       * @memberof solvy.worker.GetDocumentRequest
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      GetDocumentRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
          if (!$util.isString(message.id)) return "id: string expected";
        return null;
      };

      /**
       * Creates a GetDocumentRequest message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.GetDocumentRequest
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.GetDocumentRequest} GetDocumentRequest
       */
      GetDocumentRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.GetDocumentRequest)
          return object;
        let message = new $root.solvy.worker.GetDocumentRequest();
        if (object.id != null) message.id = String(object.id);
        return message;
      };

      /**
       * Creates a plain object from a GetDocumentRequest message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.GetDocumentRequest
       * @static
       * @param {solvy.worker.GetDocumentRequest} message GetDocumentRequest
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      GetDocumentRequest.toObject = function toObject(message, options) {
        if (!options) options = {};
        let object = {};
        if (options.defaults) object.id = "";
        if (message.id != null && message.hasOwnProperty("id"))
          object.id = message.id;
        return object;
      };

      /**
       * Converts this GetDocumentRequest to JSON.
       * @function toJSON
       * @memberof solvy.worker.GetDocumentRequest
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      GetDocumentRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return GetDocumentRequest;
    })();

    worker.GetDocumentResponse = (function () {
      /**
       * Properties of a GetDocumentResponse.
       * @memberof solvy.worker
       * @interface IGetDocumentResponse
       * @property {Uint8Array|null} [binary_document] GetDocumentResponse binary_document
       */

      /**
       * Constructs a new GetDocumentResponse.
       * @memberof solvy.worker
       * @classdesc Represents a GetDocumentResponse.
       * @implements IGetDocumentResponse
       * @constructor
       * @param {solvy.worker.IGetDocumentResponse=} [properties] Properties to set
       */
      function GetDocumentResponse(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * GetDocumentResponse binary_document.
       * @member {Uint8Array} binary_document
       * @memberof solvy.worker.GetDocumentResponse
       * @instance
       */
      GetDocumentResponse.prototype.binary_document = $util.newBuffer([]);

      /**
       * Creates a new GetDocumentResponse instance using the specified properties.
       * @function create
       * @memberof solvy.worker.GetDocumentResponse
       * @static
       * @param {solvy.worker.IGetDocumentResponse=} [properties] Properties to set
       * @returns {solvy.worker.GetDocumentResponse} GetDocumentResponse instance
       */
      GetDocumentResponse.create = function create(properties) {
        return new GetDocumentResponse(properties);
      };

      /**
       * Encodes the specified GetDocumentResponse message. Does not implicitly {@link solvy.worker.GetDocumentResponse.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.GetDocumentResponse
       * @static
       * @param {solvy.worker.GetDocumentResponse} message GetDocumentResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      GetDocumentResponse.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.binary_document != null &&
          Object.hasOwnProperty.call(message, "binary_document")
        )
          writer
            .uint32(/* id 1, wireType 2 =*/ 10)
            .bytes(message.binary_document);
        return writer;
      };

      /**
       * Encodes the specified GetDocumentResponse message, length delimited. Does not implicitly {@link solvy.worker.GetDocumentResponse.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.GetDocumentResponse
       * @static
       * @param {solvy.worker.GetDocumentResponse} message GetDocumentResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      GetDocumentResponse.encodeDelimited = function encodeDelimited(
        message,
        writer
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a GetDocumentResponse message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.GetDocumentResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.GetDocumentResponse} GetDocumentResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      GetDocumentResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.GetDocumentResponse();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1:
              message.binary_document = reader.bytes();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a GetDocumentResponse message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.GetDocumentResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.GetDocumentResponse} GetDocumentResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      GetDocumentResponse.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a GetDocumentResponse message.
       * @function verify
       * @memberof solvy.worker.GetDocumentResponse
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      GetDocumentResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        if (
          message.binary_document != null &&
          message.hasOwnProperty("binary_document")
        )
          if (
            !(
              (message.binary_document &&
                typeof message.binary_document.length === "number") ||
              $util.isString(message.binary_document)
            )
          )
            return "binary_document: buffer expected";
        return null;
      };

      /**
       * Creates a GetDocumentResponse message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.GetDocumentResponse
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.GetDocumentResponse} GetDocumentResponse
       */
      GetDocumentResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.GetDocumentResponse)
          return object;
        let message = new $root.solvy.worker.GetDocumentResponse();
        if (object.binary_document != null)
          if (typeof object.binary_document === "string")
            $util.base64.decode(
              object.binary_document,
              (message.binary_document = $util.newBuffer(
                $util.base64.length(object.binary_document)
              )),
              0
            );
          else if (object.binary_document.length)
            message.binary_document = object.binary_document;
        return message;
      };

      /**
       * Creates a plain object from a GetDocumentResponse message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.GetDocumentResponse
       * @static
       * @param {solvy.worker.GetDocumentResponse} message GetDocumentResponse
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      GetDocumentResponse.toObject = function toObject(message, options) {
        if (!options) options = {};
        let object = {};
        if (options.defaults)
          if (options.bytes === String) object.binary_document = "";
          else {
            object.binary_document = [];
            if (options.bytes !== Array)
              object.binary_document = $util.newBuffer(object.binary_document);
          }
        if (
          message.binary_document != null &&
          message.hasOwnProperty("binary_document")
        )
          object.binary_document =
            options.bytes === String
              ? $util.base64.encode(
                  message.binary_document,
                  0,
                  message.binary_document.length
                )
              : options.bytes === Array
              ? Array.prototype.slice.call(message.binary_document)
              : message.binary_document;
        return object;
      };

      /**
       * Converts this GetDocumentResponse to JSON.
       * @function toJSON
       * @memberof solvy.worker.GetDocumentResponse
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      GetDocumentResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return GetDocumentResponse;
    })();

    worker.DeleteDocumentRequest = (function () {
      /**
       * Properties of a DeleteDocumentRequest.
       * @memberof solvy.worker
       * @interface IDeleteDocumentRequest
       * @property {string|null} [id] DeleteDocumentRequest id
       */

      /**
       * Constructs a new DeleteDocumentRequest.
       * @memberof solvy.worker
       * @classdesc Represents a DeleteDocumentRequest.
       * @implements IDeleteDocumentRequest
       * @constructor
       * @param {solvy.worker.IDeleteDocumentRequest=} [properties] Properties to set
       */
      function DeleteDocumentRequest(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * DeleteDocumentRequest id.
       * @member {string} id
       * @memberof solvy.worker.DeleteDocumentRequest
       * @instance
       */
      DeleteDocumentRequest.prototype.id = "";

      /**
       * Creates a new DeleteDocumentRequest instance using the specified properties.
       * @function create
       * @memberof solvy.worker.DeleteDocumentRequest
       * @static
       * @param {solvy.worker.IDeleteDocumentRequest=} [properties] Properties to set
       * @returns {solvy.worker.DeleteDocumentRequest} DeleteDocumentRequest instance
       */
      DeleteDocumentRequest.create = function create(properties) {
        return new DeleteDocumentRequest(properties);
      };

      /**
       * Encodes the specified DeleteDocumentRequest message. Does not implicitly {@link solvy.worker.DeleteDocumentRequest.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.DeleteDocumentRequest
       * @static
       * @param {solvy.worker.DeleteDocumentRequest} message DeleteDocumentRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      DeleteDocumentRequest.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (message.id != null && Object.hasOwnProperty.call(message, "id"))
          writer.uint32(/* id 1, wireType 2 =*/ 10).string(message.id);
        return writer;
      };

      /**
       * Encodes the specified DeleteDocumentRequest message, length delimited. Does not implicitly {@link solvy.worker.DeleteDocumentRequest.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.DeleteDocumentRequest
       * @static
       * @param {solvy.worker.DeleteDocumentRequest} message DeleteDocumentRequest message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      DeleteDocumentRequest.encodeDelimited = function encodeDelimited(
        message,
        writer
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a DeleteDocumentRequest message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.DeleteDocumentRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.DeleteDocumentRequest} DeleteDocumentRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      DeleteDocumentRequest.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.DeleteDocumentRequest();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1:
              message.id = reader.string();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a DeleteDocumentRequest message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.DeleteDocumentRequest
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.DeleteDocumentRequest} DeleteDocumentRequest
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      DeleteDocumentRequest.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a DeleteDocumentRequest message.
       * @function verify
       * @memberof solvy.worker.DeleteDocumentRequest
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      DeleteDocumentRequest.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        if (message.id != null && message.hasOwnProperty("id"))
          if (!$util.isString(message.id)) return "id: string expected";
        return null;
      };

      /**
       * Creates a DeleteDocumentRequest message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.DeleteDocumentRequest
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.DeleteDocumentRequest} DeleteDocumentRequest
       */
      DeleteDocumentRequest.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.DeleteDocumentRequest)
          return object;
        let message = new $root.solvy.worker.DeleteDocumentRequest();
        if (object.id != null) message.id = String(object.id);
        return message;
      };

      /**
       * Creates a plain object from a DeleteDocumentRequest message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.DeleteDocumentRequest
       * @static
       * @param {solvy.worker.DeleteDocumentRequest} message DeleteDocumentRequest
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      DeleteDocumentRequest.toObject = function toObject(message, options) {
        if (!options) options = {};
        let object = {};
        if (options.defaults) object.id = "";
        if (message.id != null && message.hasOwnProperty("id"))
          object.id = message.id;
        return object;
      };

      /**
       * Converts this DeleteDocumentRequest to JSON.
       * @function toJSON
       * @memberof solvy.worker.DeleteDocumentRequest
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      DeleteDocumentRequest.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return DeleteDocumentRequest;
    })();

    worker.DeleteDocumentResponse = (function () {
      /**
       * Properties of a DeleteDocumentResponse.
       * @memberof solvy.worker
       * @interface IDeleteDocumentResponse
       */

      /**
       * Constructs a new DeleteDocumentResponse.
       * @memberof solvy.worker
       * @classdesc Represents a DeleteDocumentResponse.
       * @implements IDeleteDocumentResponse
       * @constructor
       * @param {solvy.worker.IDeleteDocumentResponse=} [properties] Properties to set
       */
      function DeleteDocumentResponse(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * Creates a new DeleteDocumentResponse instance using the specified properties.
       * @function create
       * @memberof solvy.worker.DeleteDocumentResponse
       * @static
       * @param {solvy.worker.IDeleteDocumentResponse=} [properties] Properties to set
       * @returns {solvy.worker.DeleteDocumentResponse} DeleteDocumentResponse instance
       */
      DeleteDocumentResponse.create = function create(properties) {
        return new DeleteDocumentResponse(properties);
      };

      /**
       * Encodes the specified DeleteDocumentResponse message. Does not implicitly {@link solvy.worker.DeleteDocumentResponse.verify|verify} messages.
       * @function encode
       * @memberof solvy.worker.DeleteDocumentResponse
       * @static
       * @param {solvy.worker.DeleteDocumentResponse} message DeleteDocumentResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      DeleteDocumentResponse.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        return writer;
      };

      /**
       * Encodes the specified DeleteDocumentResponse message, length delimited. Does not implicitly {@link solvy.worker.DeleteDocumentResponse.verify|verify} messages.
       * @function encodeDelimited
       * @memberof solvy.worker.DeleteDocumentResponse
       * @static
       * @param {solvy.worker.DeleteDocumentResponse} message DeleteDocumentResponse message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      DeleteDocumentResponse.encodeDelimited = function encodeDelimited(
        message,
        writer
      ) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a DeleteDocumentResponse message from the specified reader or buffer.
       * @function decode
       * @memberof solvy.worker.DeleteDocumentResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {solvy.worker.DeleteDocumentResponse} DeleteDocumentResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      DeleteDocumentResponse.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.solvy.worker.DeleteDocumentResponse();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a DeleteDocumentResponse message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof solvy.worker.DeleteDocumentResponse
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {solvy.worker.DeleteDocumentResponse} DeleteDocumentResponse
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      DeleteDocumentResponse.decodeDelimited = function decodeDelimited(
        reader
      ) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a DeleteDocumentResponse message.
       * @function verify
       * @memberof solvy.worker.DeleteDocumentResponse
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      DeleteDocumentResponse.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        return null;
      };

      /**
       * Creates a DeleteDocumentResponse message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof solvy.worker.DeleteDocumentResponse
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {solvy.worker.DeleteDocumentResponse} DeleteDocumentResponse
       */
      DeleteDocumentResponse.fromObject = function fromObject(object) {
        if (object instanceof $root.solvy.worker.DeleteDocumentResponse)
          return object;
        return new $root.solvy.worker.DeleteDocumentResponse();
      };

      /**
       * Creates a plain object from a DeleteDocumentResponse message. Also converts values to other types if specified.
       * @function toObject
       * @memberof solvy.worker.DeleteDocumentResponse
       * @static
       * @param {solvy.worker.DeleteDocumentResponse} message DeleteDocumentResponse
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      DeleteDocumentResponse.toObject = function toObject() {
        return {};
      };

      /**
       * Converts this DeleteDocumentResponse to JSON.
       * @function toJSON
       * @memberof solvy.worker.DeleteDocumentResponse
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      DeleteDocumentResponse.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return DeleteDocumentResponse;
    })();

    return worker;
  })();

  return solvy;
})());

export const google = ($root.google = (() => {
  /**
   * Namespace google.
   * @exports google
   * @namespace
   */
  const google = {};

  google.protobuf = (function () {
    /**
     * Namespace protobuf.
     * @memberof google
     * @namespace
     */
    const protobuf = {};

    protobuf.Timestamp = (function () {
      /**
       * Properties of a Timestamp.
       * @memberof google.protobuf
       * @interface ITimestamp
       * @property {number|null} [seconds] Timestamp seconds
       * @property {number|null} [nanos] Timestamp nanos
       */

      /**
       * Constructs a new Timestamp.
       * @memberof google.protobuf
       * @classdesc Represents a Timestamp.
       * @implements ITimestamp
       * @constructor
       * @param {google.protobuf.ITimestamp=} [properties] Properties to set
       */
      function Timestamp(properties) {
        if (properties)
          for (let keys = Object.keys(properties), i = 0; i < keys.length; ++i)
            if (properties[keys[i]] != null)
              this[keys[i]] = properties[keys[i]];
      }

      /**
       * Timestamp seconds.
       * @member {number} seconds
       * @memberof google.protobuf.Timestamp
       * @instance
       */
      Timestamp.prototype.seconds = $util.Long
        ? $util.Long.fromBits(0, 0, false)
        : 0;

      /**
       * Timestamp nanos.
       * @member {number} nanos
       * @memberof google.protobuf.Timestamp
       * @instance
       */
      Timestamp.prototype.nanos = 0;

      /**
       * Creates a new Timestamp instance using the specified properties.
       * @function create
       * @memberof google.protobuf.Timestamp
       * @static
       * @param {google.protobuf.ITimestamp=} [properties] Properties to set
       * @returns {google.protobuf.Timestamp} Timestamp instance
       */
      Timestamp.create = function create(properties) {
        return new Timestamp(properties);
      };

      /**
       * Encodes the specified Timestamp message. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
       * @function encode
       * @memberof google.protobuf.Timestamp
       * @static
       * @param {google.protobuf.Timestamp} message Timestamp message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      Timestamp.encode = function encode(message, writer) {
        if (!writer) writer = $Writer.create();
        if (
          message.seconds != null &&
          Object.hasOwnProperty.call(message, "seconds")
        )
          writer.uint32(/* id 1, wireType 0 =*/ 8).int64(message.seconds);
        if (
          message.nanos != null &&
          Object.hasOwnProperty.call(message, "nanos")
        )
          writer.uint32(/* id 2, wireType 0 =*/ 16).int32(message.nanos);
        return writer;
      };

      /**
       * Encodes the specified Timestamp message, length delimited. Does not implicitly {@link google.protobuf.Timestamp.verify|verify} messages.
       * @function encodeDelimited
       * @memberof google.protobuf.Timestamp
       * @static
       * @param {google.protobuf.Timestamp} message Timestamp message or plain object to encode
       * @param {$protobuf.Writer} [writer] Writer to encode to
       * @returns {$protobuf.Writer} Writer
       */
      Timestamp.encodeDelimited = function encodeDelimited(message, writer) {
        return this.encode(message, writer).ldelim();
      };

      /**
       * Decodes a Timestamp message from the specified reader or buffer.
       * @function decode
       * @memberof google.protobuf.Timestamp
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @param {number} [length] Message length if known beforehand
       * @returns {google.protobuf.Timestamp} Timestamp
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      Timestamp.decode = function decode(reader, length) {
        if (!(reader instanceof $Reader)) reader = $Reader.create(reader);
        let end = length === undefined ? reader.len : reader.pos + length,
          message = new $root.google.protobuf.Timestamp();
        while (reader.pos < end) {
          let tag = reader.uint32();
          switch (tag >>> 3) {
            case 1:
              message.seconds = reader.int64();
              break;
            case 2:
              message.nanos = reader.int32();
              break;
            default:
              reader.skipType(tag & 7);
              break;
          }
        }
        return message;
      };

      /**
       * Decodes a Timestamp message from the specified reader or buffer, length delimited.
       * @function decodeDelimited
       * @memberof google.protobuf.Timestamp
       * @static
       * @param {$protobuf.Reader|Uint8Array} reader Reader or buffer to decode from
       * @returns {google.protobuf.Timestamp} Timestamp
       * @throws {Error} If the payload is not a reader or valid buffer
       * @throws {$protobuf.util.ProtocolError} If required fields are missing
       */
      Timestamp.decodeDelimited = function decodeDelimited(reader) {
        if (!(reader instanceof $Reader)) reader = new $Reader(reader);
        return this.decode(reader, reader.uint32());
      };

      /**
       * Verifies a Timestamp message.
       * @function verify
       * @memberof google.protobuf.Timestamp
       * @static
       * @param {Object.<string,*>} message Plain object to verify
       * @returns {string|null} `null` if valid, otherwise the reason why it is not
       */
      Timestamp.verify = function verify(message) {
        if (typeof message !== "object" || message === null)
          return "object expected";
        if (message.seconds != null && message.hasOwnProperty("seconds"))
          if (
            !$util.isInteger(message.seconds) &&
            !(
              message.seconds &&
              $util.isInteger(message.seconds.low) &&
              $util.isInteger(message.seconds.high)
            )
          )
            return "seconds: integer|Long expected";
        if (message.nanos != null && message.hasOwnProperty("nanos"))
          if (!$util.isInteger(message.nanos)) return "nanos: integer expected";
        return null;
      };

      /**
       * Creates a Timestamp message from a plain object. Also converts values to their respective internal types.
       * @function fromObject
       * @memberof google.protobuf.Timestamp
       * @static
       * @param {Object.<string,*>} object Plain object
       * @returns {google.protobuf.Timestamp} Timestamp
       */
      Timestamp.fromObject = function fromObject(object) {
        if (object instanceof $root.google.protobuf.Timestamp) return object;
        let message = new $root.google.protobuf.Timestamp();
        if (object.seconds != null)
          if ($util.Long)
            (message.seconds = $util.Long.fromValue(
              object.seconds
            )).unsigned = false;
          else if (typeof object.seconds === "string")
            message.seconds = parseInt(object.seconds, 10);
          else if (typeof object.seconds === "number")
            message.seconds = object.seconds;
          else if (typeof object.seconds === "object")
            message.seconds = new $util.LongBits(
              object.seconds.low >>> 0,
              object.seconds.high >>> 0
            ).toNumber();
        if (object.nanos != null) message.nanos = object.nanos | 0;
        return message;
      };

      /**
       * Creates a plain object from a Timestamp message. Also converts values to other types if specified.
       * @function toObject
       * @memberof google.protobuf.Timestamp
       * @static
       * @param {google.protobuf.Timestamp} message Timestamp
       * @param {$protobuf.IConversionOptions} [options] Conversion options
       * @returns {Object.<string,*>} Plain object
       */
      Timestamp.toObject = function toObject(message, options) {
        if (!options) options = {};
        let object = {};
        if (options.defaults) {
          if ($util.Long) {
            let long = new $util.Long(0, 0, false);
            object.seconds =
              options.longs === String
                ? long.toString()
                : options.longs === Number
                ? long.toNumber()
                : long;
          } else object.seconds = options.longs === String ? "0" : 0;
          object.nanos = 0;
        }
        if (message.seconds != null && message.hasOwnProperty("seconds"))
          if (typeof message.seconds === "number")
            object.seconds =
              options.longs === String
                ? String(message.seconds)
                : message.seconds;
          else
            object.seconds =
              options.longs === String
                ? $util.Long.prototype.toString.call(message.seconds)
                : options.longs === Number
                ? new $util.LongBits(
                    message.seconds.low >>> 0,
                    message.seconds.high >>> 0
                  ).toNumber()
                : message.seconds;
        if (message.nanos != null && message.hasOwnProperty("nanos"))
          object.nanos = message.nanos;
        return object;
      };

      /**
       * Converts this Timestamp to JSON.
       * @function toJSON
       * @memberof google.protobuf.Timestamp
       * @instance
       * @returns {Object.<string,*>} JSON object
       */
      Timestamp.prototype.toJSON = function toJSON() {
        return this.constructor.toObject(this, $protobuf.util.toJSONOptions);
      };

      return Timestamp;
    })();

    return protobuf;
  })();

  return google;
})());

export { $root as default };
