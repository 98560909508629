import { useEffect } from "react";

export type FeatureFlag = "accounts";

const allFeatureFlags: Readonly<Record<FeatureFlag, boolean>> = {
  accounts: true,
};

export const isFeatureFlagEnabled = (() => {
  // memoize the answer to make sure it stays consistent with every page
  // load
  const ffMap = new Map<FeatureFlag, boolean>();
  return (ff: FeatureFlag): boolean => {
    if (!ffMap.has(ff)) {
      const isEnabled = !!localStorage.getItem(`ff.${ff}`);
      ffMap.set(ff, isEnabled);
      return isEnabled;
    }
    return ffMap.get(ff) as boolean;
  };
})();

function isValidFeatureFlag(v?: any): v is FeatureFlag {
  return Object.keys(allFeatureFlags).includes(v);
}

export function useQueryParamFFSetting() {
  useEffect(() => {
    const u = new URL(window.location.href);
    const qp = u.searchParams;
    const op = qp.get("ffop");
    const arg = qp.get("ffarg");
    if (!op) {
      return;
    }
    if (!isValidFeatureFlag(arg)) {
      return;
    }
    switch (op) {
      case "enable":
        enableFeatureFlag(arg);
        break;
      case "disable":
        disableFeatureFlag(arg);
        break;
    }
  }, []);
}

const enableFeatureFlag = (ff: FeatureFlag): void => {
  localStorage.setItem(`ff.${ff}`, "true");
  global.location.href = "/";
};
const disableFeatureFlag = (ff: FeatureFlag): void => {
  localStorage.removeItem(`ff.${ff}`);
  global.location.href = "/";
};

(global as any).enableFeatureFlag = enableFeatureFlag;
(global as any).disableFeatureFlag = disableFeatureFlag;
