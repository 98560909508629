import { useEffect, useState } from "react";

export enum ScreenSize {
  SM = 0,
  MD = 1,
  LG = 2,
  XL = 3,
  XXL = 4,
  XXXL = 5,
}

function getScreenSize(): ScreenSize {
  if (matchMedia("(max-width: 640px)").matches) {
    return ScreenSize.SM;
  } else if (matchMedia("(max-width: 768px)").matches) {
    return ScreenSize.MD;
  } else if (matchMedia("(max-width: 1024px)").matches) {
    return ScreenSize.LG;
  } else if (matchMedia("(max-width: 1280px)").matches) {
    return ScreenSize.XL;
  } else if (matchMedia("(max-width: 1536px)").matches) {
    return ScreenSize.XXL;
  }
  return ScreenSize.XXXL;
}

export function useScreenSize(): [ScreenSize, number] {
  const [screenSize, setScreenSize] = useState(getScreenSize());

  useEffect(() => {
    const handleResize = () => {
      if (getScreenSize() !== screenSize) {
        setScreenSize(getScreenSize());
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [screenSize]);

  return [screenSize, window.innerWidth];
}
