import * as globalThisPolyfill from "globalthis";
import { solvy } from "./protocol.pb";
import {
  encodeRequest,
  errorFromResponse,
  decodeResponse,
  badRouteError,
} from "@nkcmr/protoc-gen-twirp_js";

export function sendSolvyFLJSONRequest(method, request, options) {
  return sendSolvyFLRequest(method, request, {
    ...options,
    contentType: "application/json",
  });
}
export function sendSolvyFLProtobufRequest(method, request, options) {
  return sendSolvyFLRequest(method, request, {
    ...options,
    contentType: "application/protobuf",
  });
}
async function sendSolvyFLRequest(method, request, options) {
  const path = `/${encodeURIComponent(
    options.prefix || "twirp"
  )}/solvy.worker.SolvyFL/${method}`;
  let u = path;
  if (options.endpoint) {
    const epurl = new URL(options.endpoint);
    epurl.pathname = path;
    epurl.search = "";
    epurl.hash = "";
    u = epurl.toString();
  }
  /** @type {typeof fetch} */
  const fetcher = options.fetcher || globalThisPolyfill().fetch;
  switch (method) {
    case "Ping":
      const [body0, headers0] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.PingRequest
      );
      headers0.set("Accept", options.contentType);
      headers0.set("Twirp-Version", "v8.1.1");
      const res0 = await fetcher(u, {
        method: "POST",
        headers: headers0,
        body: body0,
      });
      const resBody0 = await res0.arrayBuffer();
      if (res0.status !== 200) {
        errorFromResponse(res0, resBody0);
      }
      return decodeResponse(
        options.contentType,
        resBody0,
        solvy.worker.PingResponse
      );
    case "ListDocuments":
      const [body1, headers1] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.ListDocumentsRequest
      );
      headers1.set("Accept", options.contentType);
      headers1.set("Twirp-Version", "v8.1.1");
      const res1 = await fetcher(u, {
        method: "POST",
        headers: headers1,
        body: body1,
      });
      const resBody1 = await res1.arrayBuffer();
      if (res1.status !== 200) {
        errorFromResponse(res1, resBody1);
      }
      return decodeResponse(
        options.contentType,
        resBody1,
        solvy.worker.ListDocumentsResponse
      );
    case "DeleteDocument":
      const [body2, headers2] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.DeleteDocumentRequest
      );
      headers2.set("Accept", options.contentType);
      headers2.set("Twirp-Version", "v8.1.1");
      const res2 = await fetcher(u, {
        method: "POST",
        headers: headers2,
        body: body2,
      });
      const resBody2 = await res2.arrayBuffer();
      if (res2.status !== 200) {
        errorFromResponse(res2, resBody2);
      }
      return decodeResponse(
        options.contentType,
        resBody2,
        solvy.worker.DeleteDocumentResponse
      );
    case "CreateDocument":
      const [body3, headers3] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.CreateDocumentRequest
      );
      headers3.set("Accept", options.contentType);
      headers3.set("Twirp-Version", "v8.1.1");
      const res3 = await fetcher(u, {
        method: "POST",
        headers: headers3,
        body: body3,
      });
      const resBody3 = await res3.arrayBuffer();
      if (res3.status !== 200) {
        errorFromResponse(res3, resBody3);
      }
      return decodeResponse(
        options.contentType,
        resBody3,
        solvy.worker.CreateDocumentResponse
      );
    case "GetDocument":
      const [body4, headers4] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.GetDocumentRequest
      );
      headers4.set("Accept", options.contentType);
      headers4.set("Twirp-Version", "v8.1.1");
      const res4 = await fetcher(u, {
        method: "POST",
        headers: headers4,
        body: body4,
      });
      const resBody4 = await res4.arrayBuffer();
      if (res4.status !== 200) {
        errorFromResponse(res4, resBody4);
      }
      return decodeResponse(
        options.contentType,
        resBody4,
        solvy.worker.GetDocumentResponse
      );
    case "PushDocumentChange":
      const [body5, headers5] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.PushDocumentChangeRequest
      );
      headers5.set("Accept", options.contentType);
      headers5.set("Twirp-Version", "v8.1.1");
      const res5 = await fetcher(u, {
        method: "POST",
        headers: headers5,
        body: body5,
      });
      const resBody5 = await res5.arrayBuffer();
      if (res5.status !== 200) {
        errorFromResponse(res5, resBody5);
      }
      return decodeResponse(
        options.contentType,
        resBody5,
        solvy.worker.PushDocumentChangeResponse
      );
  }
  badRouteError(`no handler for path ${path}`, "POST", path);
}
export function sendSolvyAccountObjectJSONRequest(method, request, options) {
  return sendSolvyAccountObjectRequest(method, request, {
    ...options,
    contentType: "application/json",
  });
}
export function sendSolvyAccountObjectProtobufRequest(
  method,
  request,
  options
) {
  return sendSolvyAccountObjectRequest(method, request, {
    ...options,
    contentType: "application/protobuf",
  });
}
async function sendSolvyAccountObjectRequest(method, request, options) {
  const path = `/${encodeURIComponent(
    options.prefix || "twirp"
  )}/solvy.worker.SolvyAccountObject/${method}`;
  let u = path;
  if (options.endpoint) {
    const epurl = new URL(options.endpoint);
    epurl.pathname = path;
    epurl.search = "";
    epurl.hash = "";
    u = epurl.toString();
  }
  /** @type {typeof fetch} */
  const fetcher = options.fetcher || globalThisPolyfill().fetch;
  switch (method) {
    case "ListDocuments":
      const [body0, headers0] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.ListDocumentsRequest
      );
      headers0.set("Accept", options.contentType);
      headers0.set("Twirp-Version", "v8.1.1");
      const res0 = await fetcher(u, {
        method: "POST",
        headers: headers0,
        body: body0,
      });
      const resBody0 = await res0.arrayBuffer();
      if (res0.status !== 200) {
        errorFromResponse(res0, resBody0);
      }
      return decodeResponse(
        options.contentType,
        resBody0,
        solvy.worker.ListDocumentsResponse
      );
    case "DeleteDocument":
      const [body1, headers1] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.DeleteDocumentRequest
      );
      headers1.set("Accept", options.contentType);
      headers1.set("Twirp-Version", "v8.1.1");
      const res1 = await fetcher(u, {
        method: "POST",
        headers: headers1,
        body: body1,
      });
      const resBody1 = await res1.arrayBuffer();
      if (res1.status !== 200) {
        errorFromResponse(res1, resBody1);
      }
      return decodeResponse(
        options.contentType,
        resBody1,
        solvy.worker.DeleteDocumentResponse
      );
    case "GetDocument":
      const [body2, headers2] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.GetDocumentRequest
      );
      headers2.set("Accept", options.contentType);
      headers2.set("Twirp-Version", "v8.1.1");
      const res2 = await fetcher(u, {
        method: "POST",
        headers: headers2,
        body: body2,
      });
      const resBody2 = await res2.arrayBuffer();
      if (res2.status !== 200) {
        errorFromResponse(res2, resBody2);
      }
      return decodeResponse(
        options.contentType,
        resBody2,
        solvy.worker.GetDocumentResponse
      );
    case "CreateDocument":
      const [body3, headers3] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.CreateDocumentRequest
      );
      headers3.set("Accept", options.contentType);
      headers3.set("Twirp-Version", "v8.1.1");
      const res3 = await fetcher(u, {
        method: "POST",
        headers: headers3,
        body: body3,
      });
      const resBody3 = await res3.arrayBuffer();
      if (res3.status !== 200) {
        errorFromResponse(res3, resBody3);
      }
      return decodeResponse(
        options.contentType,
        resBody3,
        solvy.worker.CreateDocumentResponse
      );
    case "PushDocumentChange":
      const [body4, headers4] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.PushDocumentChangeRequest
      );
      headers4.set("Accept", options.contentType);
      headers4.set("Twirp-Version", "v8.1.1");
      const res4 = await fetcher(u, {
        method: "POST",
        headers: headers4,
        body: body4,
      });
      const resBody4 = await res4.arrayBuffer();
      if (res4.status !== 200) {
        errorFromResponse(res4, resBody4);
      }
      return decodeResponse(
        options.contentType,
        resBody4,
        solvy.worker.PushDocumentChangeResponse
      );
  }
  badRouteError(`no handler for path ${path}`, "POST", path);
}
export function sendSolvyDocumentObjectJSONRequest(method, request, options) {
  return sendSolvyDocumentObjectRequest(method, request, {
    ...options,
    contentType: "application/json",
  });
}
export function sendSolvyDocumentObjectProtobufRequest(
  method,
  request,
  options
) {
  return sendSolvyDocumentObjectRequest(method, request, {
    ...options,
    contentType: "application/protobuf",
  });
}
async function sendSolvyDocumentObjectRequest(method, request, options) {
  const path = `/${encodeURIComponent(
    options.prefix || "twirp"
  )}/solvy.worker.SolvyDocumentObject/${method}`;
  let u = path;
  if (options.endpoint) {
    const epurl = new URL(options.endpoint);
    epurl.pathname = path;
    epurl.search = "";
    epurl.hash = "";
    u = epurl.toString();
  }
  /** @type {typeof fetch} */
  const fetcher = options.fetcher || globalThisPolyfill().fetch;
  switch (method) {
    case "GetDocumentInfo":
      const [body0, headers0] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.GetDocumentInfoRequest
      );
      headers0.set("Accept", options.contentType);
      headers0.set("Twirp-Version", "v8.1.1");
      const res0 = await fetcher(u, {
        method: "POST",
        headers: headers0,
        body: body0,
      });
      const resBody0 = await res0.arrayBuffer();
      if (res0.status !== 200) {
        errorFromResponse(res0, resBody0);
      }
      return decodeResponse(
        options.contentType,
        resBody0,
        solvy.worker.GetDocumentInfoResponse
      );
    case "GetDocument":
      const [body1, headers1] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.GetDocumentRequest
      );
      headers1.set("Accept", options.contentType);
      headers1.set("Twirp-Version", "v8.1.1");
      const res1 = await fetcher(u, {
        method: "POST",
        headers: headers1,
        body: body1,
      });
      const resBody1 = await res1.arrayBuffer();
      if (res1.status !== 200) {
        errorFromResponse(res1, resBody1);
      }
      return decodeResponse(
        options.contentType,
        resBody1,
        solvy.worker.GetDocumentResponse
      );
    case "CreateDocument":
      const [body2, headers2] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.CreateDocumentRequest
      );
      headers2.set("Accept", options.contentType);
      headers2.set("Twirp-Version", "v8.1.1");
      const res2 = await fetcher(u, {
        method: "POST",
        headers: headers2,
        body: body2,
      });
      const resBody2 = await res2.arrayBuffer();
      if (res2.status !== 200) {
        errorFromResponse(res2, resBody2);
      }
      return decodeResponse(
        options.contentType,
        resBody2,
        solvy.worker.CreateDocumentResponse
      );
    case "PushDocumentChange":
      const [body3, headers3] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.PushDocumentChangeRequest
      );
      headers3.set("Accept", options.contentType);
      headers3.set("Twirp-Version", "v8.1.1");
      const res3 = await fetcher(u, {
        method: "POST",
        headers: headers3,
        body: body3,
      });
      const resBody3 = await res3.arrayBuffer();
      if (res3.status !== 200) {
        errorFromResponse(res3, resBody3);
      }
      return decodeResponse(
        options.contentType,
        resBody3,
        solvy.worker.PushDocumentChangeResponse
      );
    case "DeleteDocument":
      const [body4, headers4] = encodeRequest(
        options.contentType,
        request,
        solvy.worker.DeleteDocumentRequest
      );
      headers4.set("Accept", options.contentType);
      headers4.set("Twirp-Version", "v8.1.1");
      const res4 = await fetcher(u, {
        method: "POST",
        headers: headers4,
        body: body4,
      });
      const resBody4 = await res4.arrayBuffer();
      if (res4.status !== 200) {
        errorFromResponse(res4, resBody4);
      }
      return decodeResponse(
        options.contentType,
        resBody4,
        solvy.worker.DeleteDocumentResponse
      );
  }
  badRouteError(`no handler for path ${path}`, "POST", path);
}
